@import 'src/sass/_variables'

.new-department
  .modal
    .select-multi__option
      text-align: left
    .select-items
      .ui-select-items
        display: flex
        align-items: flex-start
        justify-content: flex-start
        flex-direction: column
    .content
      .title
        font-size: 2.4rem
