@import 'src/sass/_variables'
@import 'src/sass/_keyframes'

.analytics-filters-wrapper
  position: absolute
  z-index: 7 // Has to be over the Backdrop
  top: 0
  right: 0
  display: flex
  width: 35%
  min-width: 50rem
  height: 100vh
  max-height: 100vh
  overflow-y: auto
  background-color: $white
  animation-name: slideInRight
  animation-duration: 300ms
  animation-fill-mode: both

  .analitics-filters
    width: 100%

    .person-machine-select
      display: flex
      align-items: center
      justify-content: flex-start

      svg
        width: 1.6rem
        height: 1.6rem
        margin-right: 0.8rem

  .ui-button
    &.medium
      padding: 0 1.6rem
