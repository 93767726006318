/* stylelint-disable declaration-no-important */
@import 'src/sass/_variables'

@keyframes rotating
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

.routes-backoffice-regions
  margin-top: 1rem
  .ui-modal
    .kml-kmz-modal-message
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column
      span
        margin-bottom: 0.4rem
        &:last-of-type
          margin-bottom: 0.8rem

  .ui-toast
    .kml-kmz-toast-message
      display: flex
      align-items: flex-start
      justify-content: flex-start
      flex-direction: column
      span 
        &:first-of-type
          margin-top: 0.4rem
        margin-bottom: 0.4rem
  .buttons
    display: flex
    justify-content: space-between
  .ui-list
    max-height: calc(100vh - 15rem)
    margin-top: 1rem
    margin-right: -4rem
    .field
      cursor: default
      &.name
        &:hover
          text-decoration: none
      .ui-button
        background: transparent
      .icons-reset
        width: 1.6rem
        height: 1.6rem
  .routes-backoffice-edit-region
    display: flex
  .routes-backoffice-updates
    .kml-kmz
      display: flex
      align-items: center
      label 
        white-space: nowrap
        padding-right: 0.8rem
        margin-right: 0.8rem
      .file-value
        margin-top: 0
    .measurements-wrapper
      padding: 0.8rem
      font-size: 1.2rem
      p
        padding-bottom: 0.2rem
    .region-header
      .row
        display: flex
        justify-content: space-between
        margin: 2rem 0
    .points-panels
      .panel-texts
        width: calc(100% - 3.2rem)
        > div
          width: 50%
          .panel-title
            flex-shrink: 0
            width: fit-content
            max-width: 50%
            margin-right: 0.4rem
    form
      .ui-title
        margin-bottom: 2.4rem
      .add-infra-device
        justify-content: flex-start
        &.disabled
          background: transparent
          > div
            color: $ink-lightest
      .buttons
        display: flex
        justify-content: space-between
      .ui-row
        .ui-input-number:nth-child(1)
          .ui-input-number-row
            input
              border-top-right-radius: 0
              border-bottom-right-radius: 0
              border-right: 0
        .ui-input-number:nth-child(2)
          .ui-input-number-row
            input
              border-top-left-radius: 0
              border-bottom-left-radius: 0

    .region-summary
      margin-top: 2.4rem
      .file-value
        margin-top: 0
        > div:nth-child(n+2):nth-child(-n+3)
          margin-left: 1.2rem
          line-height: 0
          .icons-download,
          .icons-trash
            width: 2.4rem
            height: 2.4rem
    .separator
      margin-top: 2.4rem
    .layers-box
      .upload
        label
          background: transparent
          cursor: pointer
          span
            font-size: 1.2rem
            font-weight: 700
            line-height: 2rem
            color: #1E6CEB
        .file-value
          margin-top: 0
      .ui-panel
        .panel-texts
          .panel-title
            max-width: 20rem
            text-overflow: clip
    .editing-point-selected
      input
        background-color: #B5BFD0
    .fake-button
      position: relative
      top: -3.4rem
      height: 3.4rem
    .slider-box
      align-items: center
      width: 20rem
      text-align: center
      .slider
        align-self: center
        margin: 0 1.4rem
  .routes-map-canvas
    z-index: -1
    display: flex
    flex-direction: row
    height: calc(100vh - 5rem)
    padding-top: 5rem

    .ui-canvas
      width: calc(100vw - 38.4rem)
      margin-left: 0.6rem
      border: 0
      canvas
        border: 0.1rem solid $rombit-blue-900

    .tooltip
      position: absolute
      top: 50%
      left: 50%
      opacity: 0.5
      width: 20rem
      background-color: $black
      cursor: pointer
      padding: 1rem
      border-radius: 0.4rem
      font-size: 1.1rem
      text-align: center
      color: $white
  .select-box
    .ui-row
      margin-bottom: 0.4rem
    .ui-row:nth-child(2)
      margin-bottom: 2.4rem
  .flex-width
    display: flex
    flex: 1
    div
      flex: 1
  .flex-block
    display: flex
  .full-screen
    position: fixed
    z-index: 3
    top: 0
    left: 0
    bottom: 0
    right: 0
    background-color: $white

  .editing-box
    margin-bottom: 3.2rem
    border-bottom: 0.2rem solid $blue

.delete-infra-warning
  background-color: rgba($red, 10%)
  padding: 1rem
  margin: 2rem 1rem
  border: 0.1rem solid $red
  border-radius: 1rem

.position-points-title
  display: flex
  align-items: center
  justify-content: space-between
  cursor: pointer
  margin-bottom: 1rem

  .title
    margin-right: 1rem !important
    margin-bottom: 0 !important

  .edit-switch
    display: flex
    align-items: center
    justify-content: center

    > span
      margin-right: 1rem

.position-points-tooltip
  transform: translate(-110%, 0) !important
  width: 15rem

.esc-help
  font-size: 1.1rem

.edit-points-collapsable-container
  .safe-space
    display: none

  svg.icons-anchor
    path
      fill: $blue !important

  .title-measurements
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%

  .simple-collapsable-item
    .simple-collapsable-header
      .simple-collapsable-leading
        margin-right: 0.8rem

      .simple-collapsable-trailing
        .simple-collapsable-arrow
          margin: 0 0.8rem

.position-points-calibration
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  width: 100%
  padding: 2rem 0
  margin: 2rem 0
  border-top: 0.1rem solid $sky
  border-bottom: 0.1rem solid $sky

  .step
    position: relative
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    margin: 1rem

    .step-body
      display: flex
      align-items: center
      justify-content: center
      flex: 1
      flex-direction: column

      .ui-input
        width: 60%
        
        input
          padding: 0.8rem
          padding-left: 0
          text-align: center
      
      button
        height: 3.6rem

      small
        margin-bottom: 1rem

  .calibration-type-switch
    display: flex
    align-items: center
    justify-content: center
    width: 100%

    & > span
      margin: 1rem
      font-weight: 600

  .calibrating
    position: relative
    display: block
    width: 2.5rem
    height: 2.5rem
    margin: 2rem
    margin-top: 0.8rem
    border: 0.2rem solid $ink-lightest
    border-radius: 50%
    animation: rotating 1s linear infinite
    &:before
      content: ""
      position: absolute
      top: -0.5rem
      left: 30%
      display: block
      width: 40%
      height: 40%
      background-color: $sky-light

  .check,
  .error
    position: absolute
    right: 0
    display: flex
    align-items: center
    justify-content: center
    width: 2.4rem
    height: 2.4rem
    background-color: $green
    margin: 1rem
    border-radius: 50%

    &.show
      opacity: 1

    svg
      width: 1.6rem
      height: 1.6rem

      path
        fill: $white

  .error
    background-color: $red
