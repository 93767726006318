@import 'src/sass/_variables'

.empty-state-container
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  width: 100%
  height: 100%

  .icon
    display: flex
    align-items: center
    justify-content: center
    width: 10rem
    height: 10rem
    background-color: $sky-light
    margin-bottom: 2.4rem
    border-radius: 50%

    svg
      width: 6.4rem
      height: 6.4rem

      path
        fill: $ink-lighter

  .text
    span
      font-size: 1.6rem
      font-weight: $fw-semibold
