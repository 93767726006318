@import 'src/sass/_variables'

.main-content-header-filters-button
  position: relative

  &.any-filter-active
    & > .ui-button
      position: relative

      &:after
        content: ""
        position: absolute
        top: -0.5rem
        right: -0.5rem
        display: block
        width: 1rem
        height: 1rem
        background-color: $rombit-blue-600
        border-radius: 50%

  .quick-filters-menu
    position: absolute
    z-index: 2
    display: flex
    align-items: flex-start
    justify-content: flex-start
    flex-direction: column
    // Factor in button's width
    transform: translateX(calc(-100% + 3.2rem))
    width: 30rem
    background-color: $white
    margin-top: 0.8rem
    border: 0.1rem solid $r-medium-grey
    border-radius: 0.8rem
    box-shadow: 0 0 0.3rem 0 $sky-dark

    .header
      display: flex
      align-items: flex-start
      justify-content: flex-start
      width: 100%
      padding: 1.2rem
      border-bottom: 0.1rem solid $r-medium-grey

      span
        font-size: 1.6rem
        font-weight: $fw-semibold

    .body
      display: flex
      align-items: flex-start
      justify-content: flex-start
      flex-direction: column
      width: 100%

      .tile
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
        padding: 1.2rem

        span
          font-size: 1.4rem
