@import 'src/sass/_variables'

.routes-contractors
  > button:first-child
    position: absolute
    top: 12rem
    right: 5rem
    display: flex
    align-items: center

  .ui-module-header
    > .header-container
      margin-left: 0
    .btn-export
      margin-right: 0
    .btn-create-contractor
      margin-left: 0.8rem

  .header-container
    width: 100%
    .ui-button.back
      padding: 0
    .internal-header
      display: flex
      align-items: center
      justify-content: space-between
    h2
      font-size: 2rem
      font-weight: $fw-bold
      line-height: 2.4rem
      color: $ink-light

  .header-container2
    .ui-button.back
      padding: 1.6rem 0 2.4rem
    h2
      font-size: 2rem
      font-weight: $fw-bold
      line-height: 2.4rem
      color: $ink-light
