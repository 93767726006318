@import 'src/sass/_variables'

.ui-card
  display: flex
  justify-content: space-between
  flex-direction: column
  width: auto
  min-width: 29.6rem
  height: 12rem
  background-color: $white
  padding: 2rem 2.4rem 2.4rem
  padding-bottom: 1.2rem
  margin-right: 1.6rem
  border-radius: 0.4rem
  box-shadow: 0 0 0.4rem $ink-lightest
  &.clickeable
    cursor: pointer
  &.border-none
    padding-left: 3.2rem
  &.border-solid
    border-left: 0.8rem solid
  &.border-dotted
    border-left: 0.8rem dotted
  &.none
    border-color: transparent
  &.default
    border-color: $green
  &.warning
    border-color: $orange
  &.error
    border-color: $red
  &.disabled
    border-color: $ink-lightest
  .card-header
    display: flex
    align-items: center
    justify-content: space-between
    .card-header-texts
      width: calc(100% - 3.2rem)
      .title
        +ellipsis
        font-size: 1.6rem
        font-weight: $fw-bold
        line-height: 2rem
      .subtitle
        +ellipsis
        font-size: 1.2rem
        font-weight: $fw-regular
        line-height: 1.6rem
    .card-header-options
      &:empty
        height: 3.2rem
  .card-body
    min-height: 2rem
    margin-top: 0.8rem
    font-size: 1.2rem
    font-weight: $fw-regular
    line-height: 1.6rem
  .card-footer
    display: flex
    align-items: center
    justify-content: space-between
    height: 3rem
    padding-right: 1.4rem
    .status,
    .info
      display: flex
      align-items: center
      font-size: 1.2rem
      font-weight: $fw-regular
      line-height: 1.6rem
      > div:first-child
        &:not(:empty)
          margin-right: 0.8rem
          line-height: 0
    .status
      &:not(.none)
        padding: 0 0.8rem
        border-radius: 0.8rem
      &.error,
      &.warning,
      &.default,
      &.disabled
        color: $white
      &.none
        background-color: transparent
      &.default
        background-color: $green
      &.warning
        background-color: $orange
      &.error
        background-color: $red
      &.disabled
        background-color: $ink-lightest
    .info
      > div:nth-child(2)
        font-size: 1.4rem
        font-weight: $fw-regular
        line-height: 1.6rem
        color: $ink-lightest

.ui-card-evacuation
  width: 25.9rem
  height: 10rem
