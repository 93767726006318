@import 'src/sass/_variables'

.area-details-header
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  margin-bottom: 1.6rem

  .leading
    display: flex
    align-items: center
    justify-content: flex-start

    .color-box
      display: block
      width: 1.2rem
      min-width: 1.2rem
      height: 1.2rem
      min-height: 1.2rem
      margin-right: 0.8rem
      border-radius: 0.4rem

    .title
      margin-right: 0.8rem

      span
        font-size: 2rem
        font-weight: $fw-semibold
        color: $ink-light

  .trailing
    display: flex
    align-items: center
    justify-content: flex-end
    min-width: 35rem

    .area-overview-last-updated
      margin-right: 1.6rem

    .arrow
      display: flex
      align-items: center
      justify-content: center
      width: 3.2rem
      height: 3.2rem
      cursor: pointer
      margin-right: 0.8rem
      border-radius: 0.6rem

      &:hover
        background-color: $sky-light

      svg
        path
          fill: $ink-light

    .close
      display: flex
      align-items: center
      justify-content: center
      width: 3.2rem
      height: 3.2rem
      cursor: pointer
      border-radius: 0.6rem

      &:hover
        background-color: $sky-light

      svg
        path
          fill: $ink-light
