@import 'src/sass/_variables'

.rules-list
  .rule
    // Details
    &.active
      .details
        opacity: 1
        visibility: visible
        transition: all 0.15s ease-in
    .details
      opacity: 0
      visibility: hidden
      transition: all 0.15s ease-out
      width: 100%
      cursor: auto
      padding: 3.2rem 2.4rem
      border-top: 0.1rem solid $sky
      font-size: 1.4rem
      color: $ink-light
      .container
        display: flex
        justify-content: space-between
        .info
          display: flex
          flex-direction: column
          flex-shrink: 0
          width: 50rem
          max-height: 22.5rem
          overflow: hidden auto
          .trigger
            margin-bottom: 1.6rem
          .ui-rule-actions
            overflow-y: visible
            max-height: 100%

        .assign-container
          position: relative
          width: 35rem
          padding: 0 2.4rem
          > p
            font-weight: $fw-bold
          > .ui-resizable-container
            margin-bottom: 0
            > .view-more
              display: none
          .show-more
            align-items: flex-start
            width: 100%
            &:before
              content: ""
              position: absolute
              top: -0.4rem
              left: -1rem
              width: calc(100% + 1.2rem)
              height: 0.8rem
              background: linear-gradient(to bottom, transparent, $white 50%)
          .pills-container
            display: flex
            flex-flow: wrap
            // one-pill at least and to match .info minumun possible height
            min-height: 2.8rem
            margin-top: 1rem
             // compensation for pills
            margin-left: -0.4rem
            text-overflow: ellipsis
            overflow: hidden
            white-space: nowrap
            .ui-pill
              margin: 0.2rem 0.4rem
              span
                +ellipsis(24rem)
            &.with-label
              overflow-y: scroll
              display: flow-root
              height: 18rem
              .ui-pill
                margin: 0.6rem 0.3rem
                span
                  max-width: 12rem
            .title-label
              width: 100%
              font-size: 1.2rem
              line-height: 1.6rem
              color: $ink-lightest
          .assigned-pop-up
            position: absolute
            z-index: 1
            left: -3rem
            bottom: 4rem
            width: 20rem
            height: 23rem
            background-color: $sky-lighter
            padding: 1rem 0.4rem 0 1.6rem
            border-radius: 0.4rem
            box-shadow: 0 0.4rem 0.4rem transparentize($ink, 0.75)
            .header
              display: flex
              justify-content: space-between
              margin-right: 1rem
              .icons-close
                width: 1.6rem
                cursor: pointer
            .tags
              overflow-y: scroll
            .pills-container.with-label
              > .ui-pill
                height: initial
                min-height: 2.4rem
                > span
                  padding: 0.4rem 0
                  white-space: normal
                  overflow: visible
                  line-height: 1

        .infra-down-type-pills
          display: flex
          align-items: flex-start
          justify-content: flex-start
          flex-direction: column
          width: 100%

          .title
            margin-bottom: 1rem
            font-weight: $fw-bold

          .pill-container
            display: flex
            align-items: flex-start
            justify-content: flex-start
            flex-wrap: wrap

            .ui-pill
              margin: 0 0.8rem 0.8rem 0

      footer
        display: flex
        flex-flow: row nowrap
        margin-top: 1.6rem
        .assignees
          flex-shrink: 0
          width: 48rem
          h3
            margin-bottom: 0.8rem
            font-size: 1.4rem
            line-height: 2rem
            color: $ink
          > div
            display: flex
            flex-flow: row wrap
      .created-info
        flex-grow: 1
        align-self: center
        margin-top: 2.4rem
        text-align: left
        color: $ink-lighter
        div
          display: inline
