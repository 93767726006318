@import 'src/sass/_variables'

.workers-or-machines-table
  width: 100%

  .header
    display: flex
    align-items: center
    justify-content: flex-start
    height: 4rem
    background-color: $sky-lighter
    border-bottom: 0.1rem solid $sky

    .column
      display: flex
      align-items: center
      justify-content: flex-start
      width: 100%

      &.name
        min-width: 16rem
        padding-left: 1.6rem

      &.internalId
        min-width: 12rem

      &.type,
      &.contractor,
      &.region,
      &.deviceId
        min-width: 8rem

      &.position
        justify-content: center

      span
        font-size: 1.4rem
        font-weight: $fw-semibold

  .body
    display: flex
    align-items: flex-start
    justify-content: flex-start
    flex-direction: column
    width: 100%
    overflow: auto

    .row
      display: flex
      align-items: center
      justify-content: flex-start
      width: 100%
      padding-top: 0.4rem
      padding-bottom: 0.4rem
      border-bottom: 0.1rem solid $sky

      &:nth-of-type(even)
        background-color: $sky-lighter

      .cell
        width: 100%

        &.name
          display: flex
          align-items: center
          justify-content: flex-start
          min-width: 16rem
          padding-left: 1.6rem

          .icon
            display: flex
            align-items: center
            justify-content: center
            // Had to hack these icons due to the SVG having a hardcoded size
            transform: scale(0.7)
            width: 4rem
            min-width: 4rem
            height: 4rem
            min-height: 4rem
            margin-right: 0.4rem
            margin-left: -0.4rem
            border-radius: 50%
            line-height: 3rem

            .default-icon
              svg
                width: 2.4rem
                height: 2.4rem

            svg
              display: flex
              align-items: center
              justify-content: center
              width: 3.2rem
              height: 3.2rem

              path
                fill: $white

        &.internalId
          min-width: 12rem

        &.type,
        &.contractor,
        &.region,
        &.deviceId
          min-width: 8rem

        &.position
          justify-content: center

          .device-position
            display: flex
            align-items: center
            justify-content: center

            &.disabled
              .ui-button
                background: none
                cursor: auto
                border: none

            .ui-button,
            .no-position-container
              display: flex
              align-items: center
              justify-content: center
              width: 3.2rem
              height: 3.2rem

              svg.icons-my-location
                width: 2rem
                height: 2rem
                margin: 0
                &.active
                  path
                    fill: $green

        .text
          overflow: hidden
          text-overflow: ellipsis
          white-space: nowrap
          word-break: break-word
          overflow-wrap: break-word
          margin-right: 0.8rem
          font-size: 1.2rem
