@import 'src/sass/_variables'

.routes-management
  .ui-topbar
    .end
      display: flex
      align-items: center
      justify-content: flex-start
      flex-flow: row nowrap
      width: 100%
    .topbar-content
      .btn-evacuation
        margin-left: auto
    .assets-menu
      margin-right: auto
      margin-left: 0
      .component
        > div
          display: flex
          align-items: center
          h2
            display: flex
            align-items: center
            white-space: nowrap
            margin-right: 0.8rem
            font-family: $outfit
            font-size: 2rem
            font-weight: $fw-bold
            line-height: 2.4rem
            color: $ink-light
          .icons-chevron-down
            path
              fill: $ink-light

    .topbar-controls
      display: flex
      align-items: center
      justify-content: flex-end
      width: 100%
      margin-right: 0
    .topbar-time-sheet
      display: flex
      align-items: center
      justify-content: flex-start
      width: 100%
      margin-right: 0
  .main-container
    display: flex
    flex-flow: row nowrap
    width: 100%
    margin: 0
    .ui-menu
      height: 100vh
    .routes-people-management,
    .routes-certificates
      .ui-module-header
        .ui-table-toolbar
          margin-left: auto
        .btn-add-new
          margin-left: 0.8rem
    .internal-container
      display: flex
      flex-flow: column nowrap
      width: calc(100% - 30.4rem)
      margin: 2rem 4.4rem 4.8rem 3.2rem
      .back-row
        display: flex
        align-items: center
        justify-content: flex-start
        flex-direction: row
        width: fit-content
        span
          font-size: 1.2rem
          line-height: 1.6rem
          color: $ink-light
    .ui-alert
      &.info
        width: 100%
        background-color: $white

      .ui-link
        margin: auto 0.8rem auto 1.6rem
        border-bottom: 0

      .clear-filter
        margin: auto auto auto 0.8rem
        font-weight: 700
        text-decoration: underline
