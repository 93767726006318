@import 'src/sass/_variables'

.tabs-field-records
  width: 100%
  overflow: auto
  padding-top: 1.6rem
  padding-bottom: 1.6rem

  .event-log
    display: flex
    align-items: flex-start
    justify-content: flex-start
    flex-direction: column
    margin-bottom: 1.6rem

    .top
      display: flex
      align-items: center
      justify-content: center
      margin-bottom: 0.8rem

      & > .icon
        display: flex
        align-items: flex-start
        justify-content: flex-start
        width: 2.4rem
        height: 2.4rem
        margin-right: 1.6rem

      .content
        display: flex
        align-items: flex-start
        justify-content: flex-start

        .box
          display: flex
          align-items: flex-start
          justify-content: flex-start
          flex-direction: column
          width: 40rem
          padding: 0.8rem 1.6rem
          border: 0.1rem solid $sky
          border-radius: 0.8rem

          .title
            span
              font-size: 1.4rem
              font-weight: $fw-semibold

          .subtitle
            display: flex
            align-items: flex-start
            justify-content: flex-start

            & > span
              margin-right: 0.4rem
              font-size: 1.4rem

            .event-id-link
              cursor: pointer

              span
                font-size: 1.4rem
                color: $rombit-blue-600

      .live-indicator
        display: flex
        align-items: center
        justify-content: center
        width: 2.4rem
        height: 2.4rem
        background-color: $red
        margin-left: 1.6rem
        border-radius: 50%

        span
          display: flex
          align-items: center
          justify-content: center
          width: 1.4rem
          height: 1.4rem
          border: 0.1rem solid $white
          border-radius: 50%
          color: $white

    .bottom
      display: flex
      align-items: flex-start
      justify-content: flex-start

      .date
        margin-left: calc(2.4rem + 1.6rem)

        span
          font-size: 1.2rem
          color: $ink-lighter
