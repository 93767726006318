@import 'src/sass/_variables'

.area-overview-main-content
  display: flex
  align-items: flex-start
  justify-content: flex-start
  flex-direction: column
  width: 100%
  overflow: auto
  padding: 2.4rem
