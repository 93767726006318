@import 'src/sass/_variables'


.renew-container
  width: 100%
  height: calc(100vh - 5.6rem)
  padding: 0 4.8rem 0 0
  margin: 0 0 0 3.2rem
  overflow: hidden auto

.back-row-renew
  width: 80rem
  margin: 1.6rem auto 0.8rem
  .ui-button.back
    padding: 0

.update-certificates
  display: flex
  justify-content: center
  width: 80rem
  margin: 0.8rem auto
  .update-certificates-card
    +base-card
    width: 100%
    padding: 4.8rem
    margin-bottom: 4.8rem

    // header
    header
      .ui-title
        height: initial
        padding: 0
        margin-bottom: 1.6rem
      > p
        margin-bottom: 1.6rem
        font-size: 1.2rem
        font-weight: $fw-regular
        line-height: 1.6rem
        color: $ink-light

    // Panel
    .certificates-panel
      padding: 3.2rem 1.6rem 2.4rem
      margin-bottom: 0.8rem
      &.up-to-date
        background-color: $green-lighter
        .ui-panel
          .panel-texts
            .panel-title
              span:nth-child(1)
                margin-right: 0.4rem
                color: $green
          .panel-content
            .subtitle
              color: $green

      &.about-to-expire
        background-color: $orange-lighter
        .ui-panel
          .panel-texts
            .panel-title
              max-width: initial
              span:nth-child(1)
                margin-right: 0.4rem
                color: $orange-darker
          .panel-content
            .subtitle
              color: $orange-darker

      &.expired
        background-color: $red-lighter
        .ui-panel
          .panel-texts
            .panel-title
              max-width: initial
              span:nth-child(1)
                margin-right: 0.4rem
                color: $red
              .certificate-name
                +ellipsis(16rem)
                font-weight: $fw-bold
                color: $ink
            .panel-content
              .subtitle
                color: $red-darker

      .ui-panel
        margin-bottom: 0

    // Input for upload
    .ui-input.upload
      background: $sky-lighter
      margin-bottom: 1.6rem
      border: 0.1rem dashed $sky-dark
      border-radius: 0.4rem
      .dropzone
        padding: 0.8rem 0  1.6rem
      .input-container
        label
          width: 100%
          height: 100%
          background-color: transparent
          > span
            display: flex
            align-items: center
            justify-content: center
            width: 100%
            margin: 0.8rem 0
            svg
              margin-right: 0.8rem
              path
                fill: $rombit-blue-600
            // attach text
            > span
              font-size: 1.2rem
              line-height: 1.6rem
        .file-value:empty
          margin: 0
      // Consider to add this in the upload component
      .helper-text
        font-size: 1.2rem
        line-height: 1.6rem
        color: $ink-lighter

    // Added files
    .added-files
      position: relative
      background: $white
      padding: 1.6rem
      padding-right: 0.6rem
      margin-bottom: 0.8rem
      border: 0.1rem solid $sky-dark
      border-radius: 0.4rem
      h4
        position: absolute
        top: -1.6rem
        left: 1rem
        background-color: $white
        padding: 0.8rem
        font-size: 1.2rem
        font-weight: $fw-regular
        line-height: 1.6rem
        color: $ink-lighter
      .list
        overflow-y: auto
        max-height: 15rem
        padding-right: 1rem
        .ui-panel
          .panel-texts
            width: 90%
            max-width: 90%
            .wrapped-text
              &:nth-child(1)
                max-width: 100%
          .panel-options
            &:hover
              cursor: pointer
          &:last-child
            margin-bottom: 0
      .skeleton
        display: flex
        align-items: center
        flex-direction: column
        margin-top: 1.6rem
        margin-bottom: 1.6rem
        > div
          width: 80%
          height: 2.2rem
          border-radius: 0.4rem
          &:nth-child(1)
            background-color: $sky
            margin-bottom: 0.4rem
          &:nth-child(2)
            background: linear-gradient(transparentize($sky, 0.3) , $white)
            margin-bottom: 1.6rem
      p
        font-size: 1.2rem
        font-weight: $fw-bold
        line-height: 1.6rem
        color: $ink

    .select-expiration-date
      display: flex
      flex-direction: column
      margin-bottom: 1.6rem
      label
        margin-left: 0
        font-size: 1.4rem
        line-height: 1.8rem
        color: $ink
      .ui-row
        //  Force the GPU to re-render this div to avoid visual glitches.
        transform: translateZ(0)
        margin-bottom: 0.8rem
        .SingleDatePicker
          width: 45%
          .SingleDatePickerInput
            justify-content: space-between
  footer
    display: flex
    justify-content: space-between
    flex-flow: row nowrap
    .ui-button.back
      visibility: hidden
