@import 'src/sass/_variables'

.bulk-update-beacons
  display: flex
  align-items: flex-start
  justify-content: flex-start
  flex-direction: column
  padding: 2rem 0
  margin: 2rem 0
  border-top: 0.1rem solid transparentize($ink, 0.9)
  border-bottom: 0.1rem solid transparentize($ink, 0.9)

  // Title
  & > span
    margin-bottom: 2rem

  .buttons-row
    display: flex
    align-items: center
    justify-content: flex-end
    width: 100%

    button
      margin-right: 1rem

    & > .file

      .input-container
        width: 8.4rem

        label
          width: auto
          background-color: $rombit-blue-600
          font-size: 1.2rem
          font-weight: $fw-regular
          color: $white

          span
            text-overflow: clip

      .file-value
        display: none
