@import 'src/sass/_variables'

.area-overview-main-content-header
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%

  .title-tooltip
    display: flex
    align-items: center
    justify-content: center

    .title
      margin-right: 0.8rem

      span
        font-size: 2rem

    .tootlip
      width: 2rem
      height: 2rem

      svg
        width: 2rem
        height: 2rem

  .trailing
    display: flex
    align-items: center
    justify-content: center

    .buttons
      display: flex
      align-items: center
      justify-content: center

      .ui-button.white
        width: 3.2rem
        height: 3.2rem
        margin-right: 0.8rem

        svg
          width: 2rem
          height: 2rem
          margin: 0
