@import 'src/sass/_variables'

.area-overview-main-content-monitored-areas
  display: flex
  align-items: flex-start
  justify-content: flex-start
  flex-direction: column
  width: 100%
  background-color: $white
  padding: 1.6rem
  border: 0.1rem solid $r-medium-grey
  border-radius: 0.8rem

  .no-areas-found
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%

    span
      opacity: 0.4
      font-size: 1.6rem
