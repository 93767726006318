@import 'src/sass/_variables'
@import 'src/sass/_mixins'

.monitored-areas-header
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%

  .title
    span
      font-size: 1.6rem
      font-weight: $fw-semibold

  .trailing
    display: flex
    align-items: center
    justify-content: center

    .search
      .area-overview-search
        position: relative
        display: flex
        align-items: center
        justify-content: center
        width: 3.2rem
        background-color: $white
        cursor: pointer
        padding: 0 1rem
        margin-right: 0.8rem
        border: 0.1rem solid $sky-dark
        border-radius: 0.4rem

        &.focus
          width: 28rem

          .search-input
            width: 100%
        
        svg
          width: 1.6rem
          height: 1.6rem

        .search-input
          width: 0
          input
            width: 100%
            height: 3rem
            background: none
            padding: 1rem
            border: 0

        .clear
          position: absolute
          top: 0
          right: 0
          display: flex
          align-items: center
          justify-content: center
          width: 3rem
          height: 3rem
          cursor: pointer

    .sort-by
      min-width: 28rem
