@import 'src/sass/_variables'

.manage-areas-header
  position: sticky
  z-index: 1
  top: 0
  display: flex
  align-items: center
  justify-content: flex-start
  height: 6.4rem
  background-color: $white
  padding: 0 1.6rem
  border-bottom: 0.1rem solid $sky

  .close
    svg
      margin-right: 1.6rem
      path
        fill: $ink-light

  .title
    width: 100%
    span
      font-size: 2rem
      font-weight: $fw-semibold
      line-height: 2.4rem
      color: $ink-light

  .apply-button
    margin-left: auto
