@import 'src/sass/_variables'

.area-details-status
  display: flex
  align-items: flex-start
  justify-content: flex-start
  flex-direction: column
  width: 100%
  margin-bottom: 2.4rem

  .numbers
    display: flex
    align-items: flex-start
    justify-content: flex-start
    margin-bottom: 1.6rem

    .people
      margin-right: 1.6rem

    .people,
    .machines
      display: flex
      align-items: center
      justify-content: flex-start

      .icon
        display: flex
        align-items: center
        justify-content: center
        width: 2.4rem
        height: 2.4rem
        margin-right: 0.8rem

        svg
          width: 2.4rem
          height: 2.4rem

      .number
        span
          font-size: 2.4rem
          font-weight: $fw-semibold

  .area-data-locate
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%

    .area-data
      display: flex
      align-items: flex-start
      justify-content: flex-start

      .area-type,
      .area-region,
      .last-field-record
        display: flex
        align-items: flex-start
        justify-content: flex-start
        flex-direction: column
        margin-right: 2.4rem

        .title
          margin-bottom: 0.8rem

          span
            font-size: 1.4rem
            font-weight: $fw-semibold

        .value
          display: flex
          align-items: flex-start
          justify-content: flex-start

          .icon
            position: relative
            display: flex
            align-items: center
            justify-content: center
            width: 2rem
            height: 2rem
            margin-right: 0.8rem

            .clock
              display: flex
              align-items: center
              justify-content: center
              width: 2rem
              height: 2rem

              svg
                width: 2rem
                height: 2rem

                path
                  fill: $orange

            .warning
              position: absolute
              bottom: 0
              right: 0
              display: flex
              align-items: center
              justify-content: center
              width: 1.2rem
              height: 1.2rem

              svg
                width: 1.2rem
                height: 1.2rem

                path
                  fill: $orange

          span
            font-size: 1.4rem

    .locate
      display: flex
      align-items: center
      justify-content: flex-end
      cursor: pointer
      padding: 0.8rem 0
      padding-right: 0.2rem
      border-radius: 0.6rem

      &.disabled
        cursor: auto

        &:hover
          background: none

        span
          color: $sky-dark

        .icon
          svg
            path
              fill: $sky-dark

      &:hover
        background-color: $sky-light

      span
        margin-right: 0.4rem
        margin-left: 0.8rem
        font-size: 1.4rem
        font-weight: $fw-semibold
        color: $rombit-blue-600

      .icon
        display: flex
        align-items: center
        justify-content: center
        width: 2rem
        height: 2rem

        svg
          width: 2rem
          height: 2rem

          path
            fill: $rombit-blue-600
