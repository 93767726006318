@import 'src/sass/_variables'

.area-metrics-graph
  display: flex
  align-items: center
  justify-content: flex-start
  flex-direction: column
  width: 100%
  border-left: 0.1rem solid $sky

  .no-values
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    background-color: $sky-lighter
    padding: 1.6rem 3.2rem

    .title
      margin-bottom: 1.6rem
      span
        font-size: 1.6rem
        font-weight: $fw-semibold

    .donut
      position: relative
      display: flex
      align-items: center
      justify-content: center
      width: 8.6rem
      height: 8.6rem
      background-color: $sky-dark
      margin-bottom: 1.6rem
      border-radius: 50%

      &:after
        content: ""
        position: absolute
        display: block
        width: 6rem
        height: 6rem
        background-color: $sky-lighter
        border-radius: 50%

    .text
      display: flex
      align-items: center
      justify-content: center

      span
        margin: 0 0.2rem
        font-size: 1.6rem

        &.strong
          font-weight: $fw-semibold

  & > .title
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 1.6rem

    span
      font-size: 1.6rem
      font-weight: $fw-semibold

    .icon
      width: 1.6rem
      height: 1.6rem
      margin-left: 0.8rem

      svg
        width: 1.6rem
        height: 1.6rem

  .graph
    display: flex
    align-items: center
    justify-content: center
    padding: 1.6rem

    .recharts-wrapper
      margin-right: 2.4rem

      path
        cursor: pointer

    .graph-labels
      display: flex
      align-items: flex-start
      justify-content: flex-start
      flex-direction: column

      .label
        display: flex
        align-items: center
        justify-content: flex-start
        margin-bottom: 0.8rem

        &.active
          .name
            span
              text-decoration: underline

        .color-box
          display: block
          width: 1rem
          height: 1rem
          margin-right: 0.8rem

        .name
          margin-right: 0.4rem

          span
            font-size: 1.2rem
            font-weight: $fw-semibold

        .value
          span
            font-size: 1.2rem
            font-weight: $fw-regular
