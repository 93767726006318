@import 'src/sass/_variables'

.routes-requests
  top: 0
  .cluster
    display: flex
    align-items: center
    justify-content: center
  .container
    // Events Dashboard Summary
    .requests-resolve
      z-index: 4 // over the backdrop
      top: 0
      width: $sidepanel-large-width
      overflow: hidden
      background-color: $sky-lighter
      > div:first-child
        height: 100%
      .close.right
        display: none
    .backdrop
      position: fixed
      z-index: 2 //over table-header
      top: 0
      left: 0
      bottom: 0
      right: 0
      display: block
      transition: all 0.3s linear
      width: 100%
      height: 100%
      background-color: transparentize($ink-light, 0.5)
    .requests-detail
      position: relative
      z-index: 4 // over the rainbox
      width: $sidepanel-large-width
      height: 100%
      padding: 2.4rem
      &.comments
        .ui-messages
          overflow: hidden scroll
          height: calc(100vh - 46.5rem)
          padding-right: 2.4rem
      header
        display: flex
        align-items: center
        justify-content: space-between
        flex-flow: row nowrap
        // TODO: WATCH CLARK!
        .information
          margin-bottom: 0.4rem
          > div:first-child
            display: flex
            flex-direction: row
            > span
              margin-right: 1rem
              font-size: 1.2rem
              font-weight: $fw-regular
              text-align: left
              line-height: 1.4rem
              color: $ink

        .button-bar
          display: flex
          .btn-icon
            width: 3.2rem
            height: 3.2rem
            background-color: transparent
            padding: 0
            margin: 0 0 0 0.4rem
            &:hover
              background-color: $sky
            &:active
              background-color: $sky-dark

            &.disabled
              [class^="icons-"]
                path
                  fill: $sky-dark
            > div
              line-height: 0
              [class^="icons-"]
                width: 2.4rem
                height: 2.4rem

              .icons-close
                width: 2rem
                height: 2rem

      // Container
      .content
        position: relative
        display: flex
        justify-content: center
        flex-direction: column

        .request-detail-actions
          display: flex
          align-items: center
          justify-content: space-between
          height: 3.2rem
          margin-bottom: 2.4rem
          > div
            &:last-child
              display: flex
              align-items: center
              .ui-button
                margin-right: 0.8rem
                > div
                  display: flex
                  align-items: center

        .request-detail-approvals
          display: flex
          flex-flow: column nowrap
          width: 100%
          margin-top: 2.4rem
          margin-bottom: 2.4rem
          > h3
            margin-bottom: 0.4rem
            font-size: 1.4rem
            font-weight: $fw-bold
            text-align: left
            line-height: 2rem
          .approvers-container
            display: flex
            flex-flow: row wrap
            margin-top: 0.8rem
            .profile
              display: flex
              align-items: center
              width: 17rem
              margin-right: 0.8rem
              margin-bottom: 1.6rem
              > div
                &:first-child
                  display: flex
                  align-items: center
                  justify-content: space-between
                  .ui-avatar
                    margin-left: 0.4rem

                .icons-circle-filled-check
                  path:first-child
                    fill: $green
                  path:nth-child(2)
                    fill: $white
                .icons-circle-filled-cross
                  path:first-child
                    fill: $red
                  path:nth-child(2)
                    fill: $white
                .empty-approver-status
                  display: flex
                  align-items: center
                  .icons-circle-filled-check
                    path:first-child
                      fill: $sky-dark
                    path:nth-child(2)
                      fill: $white

                &:last-child
                  margin-left: 1.2rem

        .request-detail-activities
          display: flex
          align-items: center
          flex-direction: column
          width: 100%
          margin-top: 1rem
          margin-bottom: 7.2rem
          .activity-container
            display: flex
            flex-flow: row nowrap
            width: 100%
            margin-bottom: 1.6rem
            > div:first-child
              margin-top: 0.8rem
              margin-right: 0.8rem
            img
              width: 2.4rem
              height: 2.4rem
            .text
              display: flex
              flex-flow: column nowrap
              > .activity-action
                display: flex
                align-items: center
                width: fit-content
                min-height: 3.8rem
                padding: 0.8rem 1.6rem
                margin-bottom: 0.4rem
                border: 0.1rem solid $sky-dark
                border-radius: 0.8rem
                font-size: 1.4rem
                line-height: 2rem
                color: $ink
                > svg
                  margin-left: 0.8rem
                .icons-circle-filled-check
                  path:first-child
                    fill: $green
                  path:nth-child(2)
                    fill: $white
                .icons-circle-filled-cross
                  path:first-child
                    fill: $red
                  path:nth-child(2)
                    fill: $white
                .status-pill
                  background-color: $ink-light
                  margin-left: 0.4rem
                  &.open
                    background: $rombit-blue-700
                  &.progress
                    background: $orange
                  &.resolved
                    background: $green
                &:empty
                  height: 3.8rem
              > span
                margin-left: 0.8rem
                font-size: 1.2rem
                line-height: 1.6rem
                color: $ink-lighter

        .request-detail-message
          margin-top: 3.2rem
          > h3
            margin-bottom: 0.4rem
            font-size: 1.4rem
            font-weight: $fw-bold
            text-align: left
            line-height: 2rem
          .files-container,
          .message-container
            margin-top: 0.8rem

          .message-container
            background-color: $sky-light
            padding: 1.6rem
            border: 0.1rem solid $sky-dark
            border-radius: 0.8rem
            > h3
              margin-bottom: 0.4rem
              font-size: 1.4rem
              font-weight: $fw-bold
              text-align: left
              line-height: 2rem
            span,
            div
              font-weight: $fw-regular
            span
              margin-top: 0.8rem
              font-size: 1.2rem
              color: $ink-lightest
            > div
              margin-top: 1.6rem
              font-size: 1.4rem
              color: $ink
            .ui-resizable-container
              margin-bottom: 0
              .view-more
                position: relative
                cursor: pointer
                margin-top: 0.8rem
                font-size: 1.2rem
                font-weight: $fw-semibold
                text-align: left
                color: $rombit-blue-600
                &:before
                  content: ""
                  position: absolute
                  top: -1.2rem
                  left: -1.2rem
                  width: calc(100% + 1.2rem)
                  height: 0.8rem
                  background: linear-gradient(to bottom, transparent, $sky-light 50%)
                &.expanded:before
                  display: none

          .files-container
            display: grid
            grid-template-columns: repeat(4, 1fr)
            grid-gap: 0.8rem
            width: 100%
            margin-top: 1rem
            .ui-file-panel
              justify-content: flex-start
              width: 100%
              .panel-options
                margin-left: auto
                [class^="icons-"]
                  width: 1.6rem
                  height: 1.6rem
              .panel-texts
                .ui-url
                  > span
                    max-width: 8rem
              .icons-file
                width: 3.2rem
                height: 3.2rem


        // Tabs header
        > .ui-tabs
          margin: 0 0 1.6rem

        // Map/Message container
        > .tabs-content
          display: flex
          align-items: flex-start
          justify-content: space-between
          width: 100%
          height: 100%
          // TODO: WATCH CLARK!
          .information
            width: 100%
            .row
              &.multiple
                width: calc(100% - 28rem)
              .ui-row
                display: flex
                align-items: flex-start
                margin-bottom: 1.6rem
                > div
                  &:first-child
                    margin-bottom: 0.4rem
                    font-size: 1.4rem
                    font-weight: $fw-bold
                    text-align: left
                    line-height: 2rem
                .ui-pill
                  max-width: 14rem
                  min-height: 2.4rem
                  span
                    max-width: 10rem
                  div.wrapped-text
                    font-size: 1.2rem
                    line-height: 1.6rem
                    color: $ink-lighter
                  &.with-icon
                    > div:first-child
                      margin-right: 0.4rem
                      line-height: 0
        // Comments tab
        .msg-wrapper
          display: flex
          justify-content: space-between
          flex-flow: column nowrap
          width: 100%
          min-height: 100%

          .request-detail-message
            margin-top: 0rem
