@import 'src/sass/_variables'
@import 'src/sass/_keyframes'

.area-overview-manage-areas-wrapper
  .manage-areas-backdrop
    position: absolute
    z-index: 6 // Has to be over the Evacuation Button and Side Bar
    top: 0
    // Factor in Main Side Bar width (weird error)
    left: -5.6rem
    display: block
    width: 100vw
    height: 100vh
    background-color: transparentize($ink, 0.6)

  .manage-areas-side-panel
    position: absolute
    z-index: 6 // Has to be over the Evacuation Button and Side Bar
    top: 0
    right: 0
    width: 50rem
    height: 100%
    background-color: $white
    animation-name: slideInRight
    animation-duration: 300ms
    animation-fill-mode: both

  .manage-areas-list-wrapper
    width: 100%
    // Substract header and footer height
    height: calc(100% - (2 * 6.4rem))
    overflow: auto
    padding: 2.4rem

  .area-overview-search
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    background-color: $sky-lighter
    padding: 0 1rem
    border: 0.1rem solid $sky-dark
    border-radius: 0.4rem

    svg
      width: 1.6rem
      height: 1.6rem

    .search-input
      width: 100%
      input
        width: 100%
        background: none
        padding: 1rem
        border: 0

    .clear
      cursor: pointer
