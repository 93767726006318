@import 'src/sass/_variables'

.no-contacts-yet,
.no-contacts-found
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  min-height: 60vh
  padding: 3rem

  .no-contacts-icon
    display: flex
    align-items: center
    justify-content: center
    width: 10rem
    height: 10rem
    background-color: $sky-light
    margin-bottom: 2.4rem
    border-radius: 50%

    svg
      width: 6.4rem
      height: 6.4rem

  .no-contacts-title
    margin-bottom: 0.4rem
    span
      font-size: 1.6rem
      font-weight: $fw-semibold
      color: $ink

  .no-contacts-text
    margin-bottom: 1.6rem
    span
      font-size: 1.2rem
      font-weight: $fw-regular
      color: $ink

.edit-delete-contact-actions
  display: flex
  align-items: center
  justify-content: flex-end

  & > .ui-button
    display: flex
    align-items: center
    justify-content: center
    width: 3.6rem
    height: 3.6rem

    &.edit-button
      margin-right: 0.8rem

    & > div
      svg
        width: 2.4rem
        height: 2.4rem
        margin: 0
        margin-right: 0

  .edit-contact-modal,
  .delete-contact-modal
    .warning-icon
      display: flex
      align-items: center
      justify-content: center
      width: 8rem
      height: 8rem
      background-color: $red-lighter
      margin-bottom: 1.6rem
      border-radius: 50%

      svg
        width: 4rem
        height: 4rem

        path
          fill: $red
