@import 'src/sass/_variables'

.monitored-areas-cards
  display: grid
  grid-template-columns: repeat(5, minmax(0, 1fr))
  grid-column-gap: 0.8rem
  grid-row-gap: 0.8rem
  width: 100%
  margin-top: 2.4rem

  .card
    display: flex
    align-items: flex-start
    justify-content: flex-start
    flex-direction: column
    cursor: pointer
    padding: 0.8rem
    border: 0.1rem solid $sky
    border-radius: 0.8rem

    &.live
      position: relative
      border: 0.2rem solid $red

      .live-indicator
        position: absolute
        top: -1rem
        right: -1rem
        display: flex
        align-items: center
        justify-content: center
        width: 2.4rem
        height: 2.4rem
        background-color: $red
        border-radius: 50%

        span
          display: flex
          align-items: center
          justify-content: center
          width: 1.4rem
          height: 1.4rem
          border: 0.1rem solid $white
          border-radius: 50%
          color: $white

    &:hover
      box-shadow: 0 0 0.2rem 0 $sky-dark

    .header
      display: flex
      align-items: flex-start
      justify-content: space-between
      width: 100%
      padding: 0.8rem
      border-bottom: 0.1rem solid $sky

      .leading
        display: flex
        align-items: center
        justify-content: flex-start
        width: calc(75% - 0.8rem)

        .color
          display: block
          width: 1.2rem
          min-width: 1.2rem
          height: 1.2rem
          min-height: 1.2rem
          margin-right: 0.8rem
          border-radius: 0.4rem

        .name
          width: 100%
          overflow: hidden
          white-space: nowrap
          word-break: break-all
          text-overflow: ellipsis
          padding-right: 0.8rem
          span
            font-size: 1.6rem
            font-weight: $fw-semibold

      .trailing
        display: flex
        align-items: center
        justify-content: flex-end
        width: 25%

        .arrow
          width: 2.4rem
          height: 2.4rem

          svg
            width: 2.4rem
            height: 2.4rem

    .body
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%
      padding: 0.8rem

      .leading
        display: flex
        align-items: flex-start
        justify-content: flex-start

        .people
          margin-right: 1.6rem

        .people,
        .machines
          display: flex
          align-items: center
          justify-content: center

          .icon
            width: 2.4rem
            height: 2.4rem
            margin-right: 0.8rem

            svg
              width: 2.4rem
              height: 2.4rem

              path
                fill: $ink-lighter

          .number
            span
              font-size: 2.4rem
              font-weight: $fw-semibold

      .trailing
        display: flex
        align-items: flex-start
        justify-content: flex-end

        .locate
          display: flex
          align-items: center
          justify-content: flex-end
          cursor: pointer
          padding: 0.4rem 0
          border-radius: 0.6rem

          &.disabled
            cursor: auto

            &:hover
              background: none

            span
              color: $sky-dark

            .icon
              svg
                path
                  fill: $sky-dark

          &:hover
            background-color: $sky-light

          span
            margin-right: 0.4rem
            margin-left: 0.4rem
            font-size: 1.4rem
            font-weight: $fw-semibold
            color: $rombit-blue-600

          .icon
            display: flex
            align-items: center
            justify-content: center
            width: 1.6rem
            height: 1.6rem

            svg
              width: 1.6rem
              height: 1.6rem

              path
                fill: $rombit-blue-600
