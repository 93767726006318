@import 'src/sass/_variables'

.manage-areas-list
  width: 100%
  margin-top: 1.6rem

  .zone-list
    margin-left: 2.4rem

  .zone-tile
    display: flex
    align-items: center
    justify-content: flex-start
    width: 100%
    margin-bottom: 0.8rem

    .icon
      display: flex
      align-items: center
      justify-content: center
      margin-right: 0.8rem
      svg
        width: 1.6rem
        height: 1.6rem

    .color-box
      display: block
      width: 1.6rem
      min-width: 1.6rem
      height: 1.6rem
      min-height: 1.6rem
      margin-right: 0.8rem
      border-radius: 0.4rem

    & > span
      cursor: pointer
      font-size: 1.4rem
