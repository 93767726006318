@import 'src/sass/_variables'

.area-overview-empty-state
  display: flex
  align-items: flex-start
  justify-content: flex-start
  width: 100%
  height: 100%
  padding: 2.4rem

  .empty-state-content
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    width: 100%
    height: 100%
    background-color: $white
    border-radius: 0.8rem

    .icon
      display: flex
      align-items: center
      justify-content: center
      width: 10rem
      height: 10rem
      background-color: $sky-light
      margin-bottom: 2.4rem
      border-radius: 50%

      svg
        width: 6.4rem
        height: 6.4rem

    .title
      margin-bottom: 0.8rem
      span
        font-size: 1.6rem

    .text
      margin-bottom: 1.6rem
      span
        font-size: 1.2rem

    .button
      .ui-button
        & > div
          display: flex
          align-items: center
          justify-content: center

        .plus-symbol
          display: flex
          align-items: center
          justify-content: center
          width: 1.4rem
          height: 1.4rem
          margin-right: 0.8rem
          border: 0.1rem solid $white
          border-radius: 0.2rem
