@import 'src/sass/_variables'

// Groups table
.groups-table
  margin: 0
  .ui-table
    .rdt_TableHead
      z-index: 3 // this can be removed once we use simple icons in the table
    .group-icons
      display: flex
      align-items: center
      justify-content: flex-start
      flex-flow: row nowrap
      .place-top
        margin-top: 0
      > div
        height: 3.2rem
        &:first-child
          z-index: 2
          margin-left: 0
        &:last-child
          z-index: 1
          margin-left: -1.2rem
        &:only-child
          z-index: 2
          margin-left: 0

      svg
        circle
          stroke-width: 0.1rem
          stroke: $white

    .rdt_TableBody
      .rdt_TableRow
        > div:last-child
          display: flex
          justify-content: flex-end
