@import 'src/sass/_variables'
@import 'src/sass/_keyframes'

.area-overview-area-details-wrapper
  .area-details-backdrop
    position: absolute
    z-index: 6 // Has to be over the Evacuation Button and Side Bar
    top: 0
    // Factor in Main Side Bar width (weird error)
    left: -5.6rem
    display: block
    width: 100vw
    height: 100vh
    background-color: transparentize($ink, 0.6)

  .area-details-side-panel
    position: absolute
    z-index: 6 // Has to be over the Evacuation Button and Side Bar
    top: 0
    right: 0
    display: flex
    align-items: flex-start
    justify-content: flex-start
    flex-direction: column
    width: 80rem
    height: 100%
    max-height: 100vh
    background-color: $white
    animation-name: slideInRight
    animation-duration: 300ms
    animation-fill-mode: both
    padding: 1.8rem

    & > .leading
      width: 100%
      height: auto

    & > .trailing
      display: flex
      align-items: flex-start
      justify-content: flex-start
      width: 100%
      height: 100%
