@import 'src/sass/_variables'

.certificate-assign-new-item
  .certificate-assign-new-item-body
    .ui-row
      &.mandatory
        label
          &:after
            content: "*"
            margin-left: 0.2rem
            color: $red

    .upload-file-wrapper
      .ui-input.upload
        .dropzone
          align-items: flex-start
          justify-content: flex-start

          .input-container
            label
              padding: 0 1rem
              & > span
                display: flex
                align-items: center
                justify-content: center

                svg
                  margin-right: 0.8rem

    .added-files
      h4
        margin-bottom: 1rem

    .disclaimer
      margin-top: 0
      margin-bottom: 1rem

    .timestamp-label
      display: flex
      align-items: center
      justify-content: center

      label
        cursor: pointer
        margin-bottom: 0

    .ui-single-date-picker
      & > div:first-of-type
        width: 100%

        .SingleDatePickerInput
          width: 100%

          .DateInput
            width: 100%
