@import 'src/sass/_variables'

.ui-topbar,
.topbar
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  height: $base-unit * 8
  background-color: $white
  padding: 0 3.2rem 0 1.6rem
  .ui-title
    > div
      display: flex
      flex-direction: row
      width: 100%
    > h2
      font-family: $outfit
      font-size: 2rem
  .topbar-content
    display: flex
    align-items: center
    flex-flow: row nowrap
    &:only-child
      align-items: center
      width: 100%
    .btn-evacuation
      margin-right: 0.8rem

    .ui-user-settings
      &:only-child
        margin-left: auto
  .rendered-icon
    background-color: $white
    cursor: pointer
    padding: 1.2rem 0 1.2rem 1.6rem
