@import 'src/sass/_variables'

.rules-input-agenda
  position: relative
  padding: 1.6rem
  margin-bottom: 1.6rem
  border: 0.1rem solid $sky-dark
  border-radius: 1rem

  &.disabled
    .search-box
      background-color: $sky

      svg
        opacity: 0.5

      input
        background-color: $sky

  .search-box
    display: flex
    align-items: center
    justify-content: flex-start
    padding: 0 1.2rem
    border: 0.1rem solid $sky-dark
    border-radius: 0.8rem

    svg
      width: 1.6rem
      height: 1.6rem
      margin-right: 0.8rem

    input
      width: 100%
      padding: 1.2rem 0
      border: none

      &::placeholder
        opacity: 0.5

  .contacts-list
    position: absolute
    z-index: 1
    display: flex
    align-items: flex-start
    justify-content: flex-start
    flex-direction: column
    width: calc(100% - 1.2rem * 2 - 0.8rem)
    max-height: 20rem
    background-color: $white
    margin-top: 0.4rem
    border: 0.1rem solid $sky-dark
    border-radius: 0.8rem
    overflow: auto

    .contact
      width: 100%
      cursor: pointer
      padding: 1rem 1.2rem
      border-bottom: 0.1rem solid $sky-dark
      font-size: 1.4rem

      &:hover
        background-color: $sky-light

      &:first-of-type
        border-radius: 0.8rem 0.8rem 0 0

      &:last-of-type
        border-bottom: none
        border-radius: 0 0 0.8rem 0.8rem

      &:only-of-type
        border-radius: 0.8rem

  .contact-not-found
    display: flex
    align-items: center
    justify-content: flex-start
    margin-top: 1.6rem
    font-size: 1.4rem

    span
      margin-right: 0.4rem

      &.bold
        margin-left: 0.4rem

    .contact-not-found-action-wrapper
      cursor: pointer
      color: $rombit-blue-600
