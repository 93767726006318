@import 'src/sass/_variables'

.rules-menu
  display: flex
  height: 100vh
  .ui-menu
    width: 28rem
    .ui-menu-options
      height: 100%
      > .menu-row-button
        .item-name
          width: 12rem
        .count
          margin-right: 2.6rem
      .folders-container
        display: flex
        flex-direction: column
        height: calc(100% - 16rem)
        .header
          display: flex
          align-items: center
          justify-content: space-between
          margin: 1.6rem 3.2rem
          h2
            font-size: 1.4rem
            font-weight: 700
            line-height: 2rem
            color: $ink

        // folder list
        .folders-content
          height: 100%
          overflow: hidden auto
          .empty-folders
            display: flex
            align-items: center
            justify-content: space-between
            flex-direction: column
            padding: 2.4rem 0
            margin-top: 55%
            > div
              margin-bottom: 1.2rem
              &:first-child
                background-color: $rombit-blue-100
                padding: 2.4rem
                border-radius: 50%
                .icons-folder
                  path
                    fill: none
                    stroke: $ink
              &:nth-child(2)
                font-size: 1.6rem
                font-weight: 700
                color: $ink
            > span
              font-size: 1.4rem
              font-weight: $fw-regular
              color: $rombit-blue-600
              &:hover
                cursor: pointer
          &:not(.empty-folders)
            > div
              width: 100%
              margin-right:0.8rem
        .menu-row-button
          padding-right: 0.8rem
        > .ui-button.light
          flex-shrink: 0
          margin: 2.4rem 2.4rem 0
