@import 'src/sass/_variables'

@keyframes spin
  100%
    transform: rotate(360deg)

.area-overview-last-updated
  display: flex
  align-items: center
  justify-content: center

  .icons-load-spinner
    animation: spin 1.5s linear infinite
    margin-right: 0.8rem

  .clock
    width: 1.6rem
    height: 1.6rem
    margin-right: 0.8rem

    svg
      width: 1.6rem
      height: 1.6rem

  .date
    margin-right: 0.8rem
    span
      font-size: 1.4rem
      color: $ink-light

  .refresh-button
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    margin-right: 3.2rem

    span
      font-size: 1.4rem
      font-weight: $fw-semibold
      color: $rombit-blue-600
