@import 'src/sass/_variables'

.ui-side-panel-row
  display: flex
  flex-direction: column
  width: 100%
  margin-bottom: 1.6rem

  // keep and eye here
  > .ui-input.row
    display: flex
    flex-direction: column

  > div
    > label:empty
      display: none

  label
    display: flex
    align-items: center
    +common-label($ink)
    .mandatory
      color: $red
    &:empty
      display: none

  &.row
    align-items: center
    flex-direction: row
  &.column
    flex-direction: column

  .separator
    width: 100%
    height: 0.1rem
    background-color: $sky-dark

  .disclaimer
    background-color: $sky
    padding: 1.6rem
    margin-top: 1.8rem
    border-radius: 0.4rem
    p
      font-size: 1.2rem
      font-weight: $fw-regular
      color: $ink-light
    .ui-link
      border-bottom: none
      font-weight: $fw-regular
      text-decoration: none
      color: $rombit-blue-600

  // Update sidepanel
  // ================
  &.subtypes
    .subtype-label
      span
        margin-left: 0.8rem
        color: $ink-lightest

  &.checkbox
    align-items: center
    flex-direction: row
    margin: 0 0 2.4rem
    .ui-checkbox
      height: 1.6rem
      label.control
        line-height: 1
    > label
      margin-bottom: 0

  // Single datepicker
  // =================
  .ui-single-date-picker
    .SingleDatePicker
      width: 100%
      .SingleDatePickerInput
        &_calendarIcon
          height: 3.6rem
        &_arrow
          position: relative
          top: 1rem
        .DateInput
          width: 100%
          height: 3.6rem
          input
            height: 3.6rem


  // Range datepicker
  // ================
  .ui-date-range-picker
    .DateRangePicker
      width: 100%
      .DateRangePickerInput
        height: 3.6rem
        &_calendarIcon
          height: 3.6rem
        .DateInput
          max-width: 12.8rem
          .DateInput_input
            max-width: 12.8rem
        &_clearDates
          top: -0.2rem


  // Certificates
  // ============
  &.certificate-summary,
  &.region-summary
    .ui-input.upload
      // reset
      label:first-child
        width: min-content
        height: 3.2rem
        padding-top: 0
        margin-bottom: 3.2rem
        color: $rombit-blue-600
        > span
          display: flex
          align-items: center
          padding-right: 1.2rem
          white-space: nowrap
        // odd compensation
        & + .file-value:empty
          margin-top: -3.2rem
        .icons-plus
          margin-right: 0.8rem
          path
            fill: $rombit-blue-600
      .file-value
        display: flex
        align-items: center
        justify-content: flex-start
        margin-top: 0
        margin-bottom: 0
        line-height: 2rem
    .attachment-row
      display: flex
      align-items: flex-start
      flex-direction: column
      margin-top: 1.6rem
    .attachment
      display: flex
      align-items: center
      font-size: 1.4rem
      font-weight: 700
      color: $ink-lighter
      .ui-url
        margin: 0 0.8rem 0 0
      > div:last-child
        .icons-trash-filled
          cursor: pointer
          line-height: 0
          path
            fill: $red
  &.timestamp
    display: flex
    align-items: center
    flex-direction: row
    margin-bottom: 1.6rem
    > .ui-checkbox
      .control
        margin-bottom: 0
    > label
        margin-bottom: 0
        font-size: 1.4rem
        font-weight: $fw-regular
        line-height: 1.6rem

  .ui-resizable-container
    .ui-resizable-container-header
      display: flex
      align-items: center
      justify-content: space-between
      transition: all 0.3s linear
      margin-bottom: 0.8rem
      label
        min-height: 2rem
        margin-bottom: 0
        font-weight: $fw-regular
        color: $ink-light

      // "add new certificate" section
      > div
        cursor: pointer
        font-size: 1.2rem
        font-weight: $fw-semibold
        line-height: 2rem
        color: $rombit-blue-600
        &.disabled
          cursor: not-allowed
          color: $ink-lighter
    .content
      > div
        margin-bottom: 1.6rem

  &.groups
    margin-bottom: 1.6rem
    .create-container
      margin-bottom: 0.8rem
    .groups-container
      &:empty
        min-height: 0.4rem
      > div
        margin-bottom: 1.6rem
        &:only-child
          margin-bottom: 0
        &:last-child
          margin-bottom: 1.6rem


  &.certificates .certificates-header,
  &.owners .owners-header,
  &.groups .groups-header,
  &.points .points-header
    display: flex
    align-items: center
    justify-content: space-between
    transition: all 0.3s linear
    margin-bottom: 1.6rem
    label
      margin-bottom: 0
      font-weight: 700
      color: $ink-light
    // "add new certificate" section
    > div
      cursor: pointer
      font-size: 1.2rem
      font-weight: 700
      line-height: 2rem
      color: $rombit-blue-600
      &.disabled
        cursor: not-allowed
        color: $ink-lighter
  .collapsible
    transition: all 0.3s ease-out
  .view-more
    cursor: pointer
    margin-top: 0.8rem
    font-size: 1.2rem
    font-weight: $fw-semibold
    text-align: left
    color: $rombit-blue-600

  &.input
    .ui-select-single
      .item-label
        +item-label
      .select-single__single-value
        .item-label
          +item-label-sizes(11.4rem, 8rem)
      .select-single__menu-list
        .item-label
          +item-label-sizes(12.4rem, 8.4rem)

      .select-options
        display: flex
        align-items: center
        height: 2.4rem
        > span
          &:nth-child(1)
            margin-right: 0.8rem
            line-height: 0
          &:nth-child(2)
            flex: 1
            padding-right: 0.4rem
          // save space for check icon
          &:nth-child(3)
            &:empty
              width: 2.4rem
        .item-label
          line-height: 1.6rem
          > div:first-child
            width: 8.2rem
          > div:nth-child(2)
            span:first-child
              width: 7.2rem

  &.gray
    color: $ink-lighter

  // For selects with error
  &.select
    position: relative
    &.error
      .ui-select-single
        .select-single__control
          background-color: $red-lighter
          border-color: $red
          box-shadow: 0 0 0 0.1rem $red inset
    label
      .mandatory
        color: $red
    > span.error
      position: absolute
      bottom: -1.8rem
      width: 100%
      min-height: 1.8rem
      margin: 0
      font-size: 1.2rem
      line-height: 1.8rem
      color: $red
