@import 'src/sass/_variables'

// Certificates table
.certificates-table
  height: 100%
  & > .ui-table-header
    height: auto
  .certificates-cards-container
    height: calc(100vh - 17.8rem)
    background-color: $white
    overflow: hidden auto
    padding: 1.6rem
    .certificates-cards
      display: grid
      grid-template-columns: repeat(3, 1fr)
      grid-auto-columns: 1fr // same as flex-shrink: 1
      grid-auto-rows: auto
      grid-gap: 1.6rem
      align-items: stretch
      padding: 0.4rem
      .ui-card
        flex-shrink: 0
        width: 100% // to reset values
        min-width: 100% // to reset values
        height: initial
        cursor: pointer
        margin-right: 0
      .card-content
        display: flex
        > div:first-child
          margin-right: 0.4rem
  .table-header-content
    display: flex
    align-items: center
    justify-content: space-between
    .table-header-content-title
      margin-left: 2rem
      font-size: 1.6rem
      font-weight: $fw-regular
      line-height: 2rem
      color: $ink-lighter

  .ui-table
    .group-name
      display: flex
      align-items: center
      width: 100%

    .item-icon-name-cell
      display: flex
      align-items: center
      justify-content: flex-start

      svg
        width: 1.6rem
        height: 1.6rem
        margin-right: 0.8rem

    .actions-cell
      display: flex
      align-items: center
      justify-content: flex-end

      .ui-button
        display: flex
        align-items: center
        justify-content: center
        width: 3.6rem
        height: 3.6rem

        &.renew-button
          margin-right: 0.8rem

        svg
          width: 1.8rem
          height: 1.8rem
          margin: 0
