.area-overview-page
  display: flex
  align-items: flex-start
  justify-content: flex-start
  flex-direction: column
  width: 100%
  // Consider Topbar height
  height: calc(100% - 6.4rem)

  &.fullscreen
    height: 100%
