@import 'src/sass/_variables'

.area-overview-main-content-area-metrics
  display: flex
  align-items: flex-start
  justify-content: flex-start
  flex-direction: column
  width: 100%
  background-color: $white
  padding: 1.6rem
  margin: 2.4rem 0
  border: 0.1rem solid $r-medium-grey
  border-radius: 0.8rem

  &.collapsed
    .header
      margin-bottom: 0

  .header
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    margin-bottom: 1.6rem

    .title
      span
        font-size: 1.6rem
        font-weight: $fw-semibold

    .collapse-button
      display: flex
      align-items: center
      justify-content: center
      width: 2.4rem
      height: 2.4rem
      cursor: pointer

  .metrics-body
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    min-height: 20rem

    .numbers
      display: flex
      align-items: flex-start
      justify-content: flex-start
      flex-direction: column
      width: 20%

      .people
        display: flex
        align-items: flex-start
        justify-content: flex-start
        margin-bottom: 1.6rem

        .icon
          width: 4rem
          height: 4rem
          margin-right: 0.8rem

          svg
            width: 4rem
            height: 4rem

        .text
          .big-number
            span
              font-size: 6.4rem
              font-weight: $fw-semibold
              line-height: 5rem

          .word
            span
              font-size: 2.4rem

      .machines
        display: flex
        align-items: center
        justify-content: flex-start

        .icon
          display: flex
          align-items: center
          justify-content: center
          width: 4rem
          height: 4rem
          margin-right: 0.8rem

          svg
            width: 3.2rem
            height: 3.2rem

        .text
          span
            font-size: 3.6rem
            font-weight: $fw-semibold
            line-height: 3.2rem

    .graphs
      display: flex
      align-items: flex-start
      justify-content: flex-start
      width: 80%
