@import 'src/sass/_variables'

.routes-settings
  height: 100%
  .topbar-controls
    display: flex
    align-items: center
    justify-content: flex-end
    width: 100%
    margin-right: 0
  .content
    display: flex
    height: 100%

  .settings-alerts,
  .settings-contacts,
  .settings-shifts-and-attendance,
  .settings-evacuation,
  .settings-new-features,
  .settings-departments,
  .settings-workspace
    .container
      flex-grow: 1
      +scrollable-container
      .box
        +base-card
        position: relative
        display: flex
        justify-content: flex-start
        flex-direction: column
        padding: 4.8rem
        margin-bottom: 4.8rem
        // Card title
        .ui-title.larger
          margin-bottom: 2.4rem
        .ui-title.medium
          margin-bottom: 0.8rem
        > .subtitle
          font-size: 1.4rem
          line-height: 2rem
          color: $ink-light

        .helper-text
          margin: 0.8rem 0 0
          font-size: 1.2rem
          line-height: 1.6rem
          color: $ink-lighter

        .ui-input-number
          margin: 2.4rem 0 2.4

        .switch-option
          margin-bottom: 2.4rem

          .subtitle
            margin-bottom: 0.8rem
          > div
            display: flex
            align-items: center
            > span
              margin-left: 1.6rem
              font-size: 1.4rem
              line-height: 2rem
              color: $ink-light
            > .ui-switch
              margin-top: 0.8rem

  .settings-alerts
    .container
      .box
        align-items: center
        p
          margin-bottom: 1.6rem
          font-size: 1.4rem
          line-height: 2rem
          color: $ink-light
        div
          > .subtitle
            margin-bottom: 1.2rem
          .ui-alert
            margin-bottom: 1.6rem
      .ui-resume-bar
        margin-bottom: 0
        border: 0.1rem solid $sky

  .settings-workspace
    .container
      .box
        .switch-option
          .ui-input-number
            margin: 1.6rem 0
            .ui-input
              input
                border-right: 0.1rem solid $input-border-color
                border-left: 0.1rem solid $input-border-color
                border-radius: $input-border-radius
                &:hover
                  border-color: $input-border-color-hover
      .analytics-block
        border-top: 0.1rem solid $sky

  .settings-shifts-and-attendance,
  .settings-evacuation
    .container
      .box
        align-items: flex-start

  .settings-shifts-and-attendance
    .container
      .box
        .ui-row
          display: flex
          align-items: center
          justify-content: space-between
          width: 100%
          margin-bottom: 2.4rem
          div
            display: flex
            align-items: flex-start
            flex-direction: column
        p
          margin-bottom: 1.6rem
          font-size: 1.4rem
          line-height: 2rem
          color: $ink-light
        .blank-state
          display: flex
          align-items: center
          width: 100%
          padding-top: 2.4rem
          border: 0.1rem solid $sky-dark
          border-radius: 0.8rem
          .icons-attendance
            width: 3.2rem
            height: 3.2rem
          .message-content
            .ui-title
              margin-bottom: 0
              h2
                font-size: 1.6rem
        .switch-option
          > div
            > span
              margin-left: auto

  .settings-evacuation
    .container
      hr
        width: 100%
        height: 0.1rem
        background-color: $sky-dark
        margin-top: 2.4rem
        border: none
      .subtitle
        font-size: 1.4rem
        line-height: 2rem
        color: $ink
      .drills
        display: flex
        align-items: flex-start
        flex-direction: column
        margin-top: 4.8rem
        .ui-button > div
          display: flex
          align-items: center
          .icons-evacuation
            margin-right: 0.4rem
            path
              fill: $white
        .drill-helper
          display: flex
          margin-bottom: 1.6rem
          > span
            font-size: 1.4rem
            line-height: 2rem
            color: $ink-lighter
          > div
            cursor: pointer
            font-size: 1.4rem
            font-weight: $fw-bold
            line-height: 2rem
            color: $rombit-blue-600
      .switch-option
        .evacuation-switch
          margin-top: 1.6rem
          > .ui-switch.large
            margin-top: 0

  .settings-new-features
    .subtitle
      margin-bottom: 0.8rem

    .container .box .switch-option
      margin-bottom: 0
      .map-switch
        margin-top: 1.6rem
        > span
          margin-top: 0.8rem

      .ui-title
        justify-content: left

        .ui-pill
          background-color: $white
          padding: 0 0.8rem
          margin-left: 0.8rem
          border: 0.1rem solid $blue-dark
          span
            color: $blue-dark

  .settings-contacts
    .container
      .ui-module-header
        justify-content: flex-end

.warning-toast-message
  display: flex
  flex-direction: column
  p:nth-child(1)
    margin-bottom: 0.4rem
  p:nth-child(2)
    margin-bottom: 1.6rem
    font-weight: $fw-regular
  a
    cursor: pointer
    text-decoration: underline
