@import 'src/sass/_variables'

.create-update-contact-wrapper
  .create-update-contact-backdrop
    position: absolute
    z-index: 6 // Has to be over the Evacuation Button and side bar
    top: 0
    left: 0
    display: block
    width: 100vw
    height: 100vh
    background-color: transparentize($ink, 0.6)

  .create-update-contact-side-panel
    position: absolute
    z-index: 7
    top: 0
    height: 100%
    animation-name: slideInRight
    animation-duration: 300ms
    animation-fill-mode: both

    &:before
      display: none

    & > div:first-child
      height: 100%
      overflow: hidden
      background-color: $white

      .header
        position: sticky
        z-index: 1
        top: 0
        display: flex
        align-items: center
        justify-content: space-between
        flex-direction: row
        height: 6.4rem // Match Topbar height
        background-color: $white
        padding: 1.6rem
        margin-bottom: 1.6rem
        border-bottom: 0.1rem solid $sky

        .leading
          display: flex
          align-items: center
          justify-content: flex-start

          .close-button
            display: flex
            align-items: center
            justify-content: center
            width: 3.6rem
            height: 3.6rem
            margin-right: 0.8rem

            .icons-close
              width: 2rem
              height: 2rem

              svg
                width: 2rem
                height: 2rem

      .create-update-contact-form
        padding: 0 2.5rem
