@import 'src/sass/_variables'

.error-bar.active ~ .dashboard .ui-side-panel
  top: 8rem

.ui-side-panel
  position: fixed
  z-index: 1
  top: $topbar-height
  display: flex
  min-width: 15rem
  background-color: $sky-light
  font-size: 1.4rem
  color: $ink-light
  &.left
    left: 0
    transition: left 0.3s ease
  &.right
    right: 0
    transition: right 0.3s ease
    width: 38.4rem
    height: 100%
    &:before
      content: ""
      position: absolute
      top: 0
      left: -3.2rem
      width: 3.2rem
      height: 100%
      box-shadow: -3.2rem 0 2.2rem -3.2rem transparentize($ink-light, 0.35) inset
  &.hidden-left
    left: -100%
    background: $sky-light
  &.hidden-right
    right: -100%
    background: $sky-light

  > div:first-child
    position: absolute
    overflow: hidden auto
    width: 100%
    height: calc(100vh - #{$topbar-height})
    .close
      position: absolute
      z-index: 1
      top: 2.4rem
      cursor: pointer
      line-height: 0
      &.left
        left: 2rem
      &.right
        right: 2.4rem
      .icons-chevron-right
        width: 1.6rem
        height: 1.6rem
      .icons-close
        width: 1.6rem
        height: 1.6rem

    .filters
      display: flex
      flex-direction: column
      width: 100%
      height: 100%
      scroll-behavior: smooth
      > .header
        display: flex
        align-items: flex-start
        justify-content: flex-start
        flex-direction: column
        padding: 2.4rem 0 1.6rem 2.4rem
        .title
          display: flex
          align-items: center
          justify-content: flex-start
          align-self: flex-start
          h3,
          div:nth-child(1)
            margin-right: 1.6rem
            font-size: 1.6rem
            font-weight: 700
            line-height: 1.6rem
            color: $ink-light
          button,
          div:nth-child(2)
            background: none
            cursor: pointer
            border: none
            font-size: 1.2rem
            font-weight: $fw-regular
            line-height: 1.2rem
            color: $rombit-blue-600

            &:disabled
              opacity: 0.6
              cursor: not-allowed
              color: $rombit-blue-600

      .tags
        display: flex
        flex-direction: column
        width: calc(100% - 4.8rem)
        margin: 0 auto 2.4rem
        font-size: 1.2rem
        color: $ink-light
        // Title
        > span:first-child
          font-weight: 700
        .content
          display: flex
          flex-wrap: wrap
        div[style]:not(.remove)
          padding-bottom: 0.4rem
        .view-more
          position: relative
          cursor: pointer
          font-weight: $fw-semibold
          color: $rombit-blue-600
          &:before
            content: ""
            position: absolute
            top: -0.8rem
            width: 100%
            height: 1rem
            background: linear-gradient(to bottom, transparent, $sky-light 50%)
          &.expanded:before
            display: none
        .ui-resizable-container
          margin-bottom: 0

      .switch-box,
      .checkbox-box,
      .radio-box,
      .clusters-box
        display: flex
        flex-direction: column
        padding: 1.6rem 2.4rem
        border-top: solid 0.1rem $sky-dark
        h4
          margin-bottom: 0.8rem
          font-size: 1.4rem
          line-height: 2rem
          color: $ink-light
        > div
          display: flex
          flex-direction: row
          > span
            margin: 0 1.6rem 0  0.8rem
            font-size: 1.4rem
            line-height: 1.4rem
            white-space: nowrap

      .switch-box,
      .checkbox-box
        > div
          > div
            display: flex
            align-items: center
            &:first-child
              margin-right: 1.6rem
            > span
              font-size: 1.4rem
              line-height: 1.4rem
              color: $ink
              white-space: nowrap

      .switch-box
        padding-bottom: 1.6rem
        .switch-header
          h4
            margin-right: 0.4rem
          // because ui-tooltip wrapper has no class and it's a div.
          .icons-circle-filled-information
            position: relative
            bottom: 0.4rem
            width: 1.6rem
            height: 1.6rem
            cursor: pointer
            path
              fill: $ink-lighter
        .switches-row
          > div
            flex-basis: 50%
            background-color: $sky
            padding: 1.2rem
            border-radius: 0.4rem
            .ui-switch
              justify-content: space-between
              flex-basis: 100%

      .checkbox-box
        padding-top: 0
        border: none
        h4
          font-size: 1.2rem
          font-weight: 700
          line-height: 1.6rem
        > div
          > div
            padding: 0 0.8rem
            &:first-child
              padding-left: 0
            > div:not(.ui-checkbox)
              margin-right: 0.4rem
            > span
              font-size: 1.2rem
              font-weight: $fw-regular
              line-height: 1.6rem

      > .form
        flex-grow: 1
        margin: 0 2.4rem
        .side-panel-select
          display: flex
          align-items: flex-start
          flex-direction: column
          margin-bottom: 1.6rem
          > label
            +common-label
          &.subtypes
            .subtype-label
              span
                margin-left: 0.8rem
                color: $ink-lightest
          &:last-child
            margin-bottom: 3.2rem
        .ui-side-panel-row
          .ui-date-range-picker.short
            .DateRangePickerInput
              .DateInput
                width: 11.2rem
              .DateInput_input
                width: 11.2rem

      .buttons
        display: flex
        align-items: center
        justify-content: flex-start
        padding-bottom: 2.4rem
        margin-top: auto
        margin-left: 2.4rem

        .ui-button
          flex-shrink: 0
          width: fit-content

  &.events-resolve
    > div:first-child
      background-color: $sky-lighter

  .panel-warning
    margin: -2.4rem 0 2.4rem
    font-size: 1.2rem
    line-height: 1.6rem
    color: red
    &:empty
      display: none
