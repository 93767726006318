/* stylelint-disable unit-allowed-list */
/* stylelint-disable order/properties-order */
@import 'src/sass/_variables'

// Map beta warning
.map-v2-beta-warning-mount
  position: absolute
  z-index: 10
  top: 10rem
  left: 50%
  display: block
  transform: translateX(-50%)
  width: fit-content
  height: 3.4rem

  .map-v2-beta-warning-anchor
    .map-v2-beta-warning
      display: flex
      align-items: center
      width: fit-content
      background: $white
      padding: 0.8rem 1.4rem
      margin: 0 auto
      border-radius: 0.2rem
      box-shadow: rgb(0 0 0 / 30%) 0 1px 4px -1px
      font-size: 1.4rem
      white-space: nowrap

      .icons-information
        width: 1.6rem
        height: 1.6rem
        margin-right: 1.4rem
        path
          fill: $rombit-blue-600

      a
        margin: 0 0.6rem
        font-weight: $fw-bold
        text-decoration: underline
        color: $ink
        &:hover
          cursor: pointer
      // Close
      div:last-child
        cursor: pointer
        margin-left: 1.4rem
        line-height: 0
        .icons-close
          width: 1.6rem
          height: 1.6rem

.map-pill
  display: flex
  align-items: center
  background: $sky
  padding: 0 0.8rem
  border-radius: 1.2rem
  font-size: 1.4rem
  color: $ink-lighter

// TOOLTIPS
.tooltip-mount
  position: absolute

  .tooltip-wrapper
    height: 0

    &:after
      content: ""
      position: absolute
      top: -0.7rem
      transform: translate(-50%,-53%) rotate(-45deg)
      width: 1.4rem
      height: 1.4rem
      background: $white
      box-shadow: -1px 1px 2px -1px rgba(0, 0, 0, 0.3)

    .tooltip
      transform: translate(-50%, -100%)
      min-width: 16rem
      max-width: 28rem
      background: $white
      padding: 0.8rem
      margin-top: -0.8rem
      border: transparent 0.2rem solid
      border-radius: 0.4rem
      box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3)
      font-size: 1.4rem
      color: $ink

      .tooltip-header
        display: flex
        align-items: center

        .tooltip-title
          display: block
          overflow: hidden
          text-overflow: ellipsis
          white-space: nowrap
          font-size: 1.6rem
          font-weight: $fw-bold
          color: $ink
          &.medium-title
            font-size: 1.4rem
            line-height: 1.6rem

        .right-buttons
          display: flex
          padding-left: 0.4rem
          margin-left: auto

          .exit
            width: 1.6rem
            height: 1.6rem
            margin-left: 0.2rem
            cursor: pointer
            line-height: 0

            svg
              width: 1.6rem
              height: 1.6rem

            .icons-close
              width: 1.6rem
              height: 1.6rem
              path
                fill: $ink-lighter
              &:hover path
                fill: $ink-light
              &:active path
                fill: $ink

      .tooltip-body
        padding-top: 0.6rem
        line-height: 1.6rem

        div
          font-size: 1.2rem
          line-height: 1.6rem
          color: $ink-light

        .ui-button
          div
            color: $white

        .item-groups
          text-overflow: ellipsis
          overflow: hidden

        .time-ago
          margin-top: 0.4rem
          font-size: 1.2rem
          line-height: 1.6rem
          color: $ink-lightest

        .item-altitude
          display: flex
          justify-content: flex-start
          align-items: center

      .tooltip-footer
        margin-top: 1.2rem

      &.cluster
        min-width: 16rem

        .cluster-pill
          margin-left: 0.8rem
          font-size: 1.2rem

        .cluster-update
          cursor: pointer
          margin-right: 0.4rem
          svg
            width: 1.6rem
            height: 1.6rem
          path
            fill: $rombit-blue-600
          &:hover path
            fill: $rombit-blue-700
          &:active path
            fill: $blue-dark

        .cluster-list
          display: flex
          flex-direction: column
          max-height: 14rem
          background: $sky-light
          padding: 0.2rem 0.2rem 0.2rem 0.8rem
          margin-top: 0.2rem
          border: 0.1rem solid $sky
          border-radius: 0.3rem
          overflow-y: scroll

          .cluster-list-element
            display: flex
            padding: 0.2rem 0

            .map-pill
              padding: 0.4rem 0.8rem
              font-size: 1.2rem
              svg
                width: 1.6rem
                min-width: 1.6rem
                height: 1.6rem
                margin-right: 0.2rem
              path
                fill: $ink-light

      &.event
        .event-priority
          display: flex
          align-items: center
          font-size: 1.4rem
          font-weight: $fw-regular

          svg
            width: 1.6rem
            height: 1.6rem
            margin-right: 0.4rem

          &.critical
            color: $red
            path
              fill: $red
          &.high
            color: $orange
            path
              fill: $orange
          &.medium
            color: $rombit-blue-700
            path
              fill: $rombit-blue-700
          &.low
            color: $ink-lighter
            path
              fill: $ink-lighter

        .event-rule-name
          padding-top: 0.2rem
          font-size: 1.6rem
          font-weight: $fw-bold
          overflow: hidden
          white-space: nowrap
          text-overflow: ellipsis

        .event-trigger
          font-size: 1.2rem

        .event-involved
          padding-top: 0.4rem
          font-weight: $fw-bold

// OVERLAY
.view-control-mount
  position: absolute
  z-index: 4
  width: 0
  height: 0
  margin: 1rem
  user-select: none

  .view-control-wrapper
    position: absolute
    display: flex
    background: white
    border-radius: 0.2rem
    box-shadow: rgb(0 0 0 / 30%) 0 1px 4px -1px

    div
      width: 1px
      background: rgb(230, 230, 230)
      margin: 5px 0

    button
      height: 4rem
      background: none
      cursor: pointer
      padding: 0 1.6rem
      border: none
      font-size: 1.6rem
      color: $ink-lighter

      &:hover
        font-weight: 500
        color: $ink-light

      &:active,
      &:disabled
        font-weight: 500
        color: $ink

.zoom-control-mount
  position: absolute
  z-index: 4
  bottom: 9.5rem
  right: 0
  width: 0
  height: 0
  margin: 1rem

  .zoom-control-wrapper
    position: absolute
    display: flex
    flex-direction: column
    transform: translateX(-100%)
    background: white
    border-radius: 0.2rem
    box-shadow: rgb(0 0 0 / 30%) 0 1px 4px -1px

    div
      height: 1px
      background: rgb(230, 230, 230)
      margin: 0 0.5rem

    button
      display: flex
      align-items: center
      justify-content: center
      width: 4rem
      height: 4rem
      background: none
      cursor: pointer
      border: 0

      img
        position: absolute
        display: none
        width: 1.8rem
        height: 1.8rem

        &:nth-child(1)
          display: block

      &:hover
        img:nth-child(1)
          display: none
        img:nth-child(2)
          display: block

      &:active
        img:nth-child(1)
          display: none
        img:nth-child(2)
          display: none
        img:nth-child(3)
          display: block

      &:disabled
        img:nth-child(1)
          display: none
        img:nth-child(2)
          display: none
        img:nth-child(3)
          display: none
        img:nth-child(4)
          display: block

.fullscreen-control-mount
  position: absolute
  z-index: 4
  bottom: 15rem
  right: 0
  width: 0
  height: 0
  margin: 1rem

  .fullscreen-control-wrapper
    position: absolute
    display: flex
    flex-direction: column
    transform: translateX(-100%)
    background: white
    border-radius: 0.2rem
    box-shadow: rgb(0 0 0 / 30%) 0 1px 4px -1px

    button
      display: flex
      align-items: center
      justify-content: center
      width: 4rem
      height: 4rem
      background: none
      cursor: pointer
      border: 0

      path
        fill: $ink-lighter
      &:hover path
        fill: $ink-light
      &:active path
        fill: $ink

.layers-control-mount
  position: absolute
  z-index: 4
  bottom: 3.2rem
  width: 100%
  height: 0
  margin: 0 0 4rem 1rem
  font-size: 1.4rem
  user-select: none

  .layers-control-wrapper
    position: absolute
    background-color: $white
    border-radius: 0.2rem
    box-shadow: rgb(0 0 0 / 30%) 0 1px 4px -1px
    &:hover
      background-color: $sky-lighter
    .layer-main
      display: flex
      align-items: center
      cursor: pointer
      padding: 0.4rem 0.8rem


      .icons-layers path
        fill: $ink-lightest

      &.on .icons-layers path
        fill: $rombit-blue-500

      div
        margin: 0 0.4rem

      svg
        width: 2.4rem
        height: 2.4rem

  .layers-options-wrapper
    position: absolute
    bottom: 0.4rem
    background: white
    border-radius: 0.2rem
    box-shadow: rgb(0 0 0 / 30%) 0 1px 4px -1px

    .layers-options
      padding: 0.4rem 0

      .layer-option
        display: flex
        align-items: center
        height: 3.2rem
        cursor: pointer
        padding: 0 1.6rem

        &:hover
          background: $sky-light

    .toggle-infra-sub-type
      display: flex
      justify-content: center
      align-items: center
      margin-left: 0.8rem

      svg path
        fill: $ink-lighter

      &:hover
        svg path
          fill: $ink

    .infra-sub-types
      display: none

      &.open
        display: block

      .layer-option
        padding-left: 4rem

.filters-control-mount
  position: absolute
  z-index: 4
  top: 0
  right: 0
  height: 0
  margin: 1rem
  font-size: 1.4rem
  user-select: none

  &.hidden
    display: none

  .filters-control-wrapper
    position: absolute
    display: flex
    transform: translateX(-100%)
    transition: transform 0.3s ease

    button
      display: flex
      align-items: center
      background: white
      cursor: pointer
      border: none
      border-radius: 0.4rem
      box-shadow: rgb(0 0 0 / 30%) 0 1px 4px -1px
      font-size: 1.6rem
      color: $ink-lighter

      &:first-child
        padding: 0.4rem
        margin-right: 1.6rem
        svg
          width: 3.2rem
          height: 3.2rem

      &:last-child
        padding: 0.8rem 1.2rem
        svg
          width: 2.4rem
          height: 2.4rem
          margin-right: 0.4rem

      path
        fill: $ink-lighter

      &:hover
        color: $ink-light
        path
          fill: $ink-light
      &:active
        color: $ink
        path
          fill: $ink

    &.open
      transform: translateX(100%)

// Filters
.filters-mount
  position: absolute
  z-index: 5
  top: 0
  right: -100%
  transition: right 0.3s ease
  width: 38.4rem
  height: 100%
  background-color: $white
  box-shadow: rgb(0 0 0 / 30%) 0 0 4px -1px
  font-size: 1.4rem
  // TODO: Set correct color here
  border-top: 0.1rem solid transparentize($ink-lightest, 0.7)

  .filters-wrapper
    display: flex
    flex-direction: column
    width: 100%
    height: calc(100vh - 5.4rem)
    overflow-y: scroll
    &::-webkit-scrollbar
      display: none

    h3
      font-size: 1.4rem
      color: $ink-light

    h4
      font-size: 1.2rem
      color: $ink-light

    .map-pill
      font-size: 1.2rem
      color: $ink-lightest

    .multi-selector
      cursor: pointer
      font-size: 1.2rem
      color: $rombit-blue-600
      &.disabled
        cursor: not-allowed
        color: $sky-dark

    .header
      display: flex
      align-items: center
      width: 100%
      padding: 2.4rem 2.4rem 1.2rem
      font-size: 1.2rem

      .filters-close-icon
        width: 2rem
        height: 2rem
        cursor: pointer
        margin-right: 1.6rem

        svg
          width: 2rem
          height: 2rem

      .header-second-block
        display: flex
        justify-content: space-between
        align-items: center
        width: 100%

        h2
          font-size: 1.6rem
          font-weight: $fw-bold
          color: $ink-light

        .multi-selector
          margin-left: 1.6rem

    .tags
      padding: 0 2.4rem 1.6rem
      font-size: 1.2rem

      div:first-child
        display: flex
        align-items: center
        h4
          margin-right: 1.2rem

      &.expanded .tag-list
        max-height: unset

      .tag-list
        display: flex
        flex-wrap: wrap
        overflow: hidden
        max-height: 9.6rem
        margin: 0.4rem 0
        color: $ink-light

        &.no-selection
          display: none

        .tag
          display: flex
          align-items: center
          background-color: $sky
          padding: 0.4rem 0.8rem
          margin-top: 0.8rem
          margin-right: 0.4rem
          border-radius: 0.3rem
          line-height: 0

          svg
            width: 1.6rem
            height: 1.6rem
            margin-right: 0.4rem

          div:last-child
            cursor: pointer
            margin-left: 0.4rem
            svg
              margin: 0
            path
              fill: $ink-lighter
            &:hover path
              fill: $ink-light
            &:active path
              fill: $ink

      &.expanded .view
        .view-all
          display: none
        .view-less
          display: initial

      &.overflown .view
        .view-all
          display: initial
        .view-less
          display: none

      .view div
        display: none
        cursor: pointer
        color: $rombit-blue-600

    .filters-box-content
      background-color: $sky-light

      h4
        padding-top: 1.6rem

      .devices-status
        display: flex
        font-size: 1.2rem
        color: $ink

        .device-status
          display: flex
          align-items: center
          cursor: pointer
          padding-top: 0.8rem
          user-select: none

          &:first-child
            margin-right: 2.4rem

    .filters-box
      // TODO: Set correct color here
      border-top: 0.1rem solid transparentize($ink-lightest, 0.7)

      &:last-child
        // TODO: Set correct color here
        border-bottom: 0.1rem solid transparentize($ink-lightest, 0.7)

      &.open
        .filters-box-header
          background-color: unset
          .map-pill
            background-color: $sky
          .icons-chevron-up
            display: unset
          .icons-chevron-down
            display: none
        .filters-box-content
          display: flex

      .filters-box-header
        display: flex
        align-items: center
        width: 100%
        background-color: $white
        cursor: pointer
        padding: 1.6rem 2.4rem

        h3
          margin-right: 0.8rem

        .map-pill
          background-color: $sky-light

        .icons-chevron-up
          display: none

        .multi-selector
          margin-right: 0.8rem
          margin-left: auto

        div:last-child
          line-height: 0

          svg
            width: 2.4rem
            height: 2.4rem


        &.devices
          justify-content: space-between

          .title-info
            display: flex
            justify-content: flex-start
            align-items: center

            h3
              margin-right: 0.8rem

          .map-info-tooltip-icon
            .map-info-tooltip
              width: 15rem

      .map-info-tooltip-icon
        position: relative
        cursor: pointer
        width: 1.4rem
        height: 1.4rem

        &:hover
          & > .map-info-tooltip
            display: block

        svg
          opacity: 0.5
          /* stylelint-disable-next-line declaration-no-important */
          width: 1.4rem !important
          /* stylelint-disable-next-line declaration-no-important */
          height: 1.4rem !important

        .map-info-tooltip
          display: none
          position: absolute
          z-index: 12
          background-color: $ink
          padding: 0.8rem
          border-radius: 0.4rem
          font-size: 1.2rem
          font-weight: $fw-regular
          text-align: center
          line-height: 1.6rem
          color: $white
          margin-top: -1rem
          transform: translate(-18%, -100%)

      .filters-box-content
        display: none
        flex-direction: column
        height: 100%
        max-height: 32rem
        overflow-y: scroll
        padding: 2.4rem


        .map-search-input
          padding-bottom: 1.6rem
          .icons-close
            width: 1.6rem
            height: 1.6rem
        .item-list
          width: 100%
          padding: 0.2rem 0
          font-size: 1.2rem
          line-height: 1.6rem
          color: $ink-light

          &.no-header .list
            display: flex
            padding-left: 0.8rem
          // only apply to first level
          &.open
            > .list-header .title
              > .icons-chevron-up
                display: unset
              > .icons-chevron-down
                display: none
              > .icons-folder-open
                display: unset
              > .icons-folder-closed
                display: none
            > .list
              display: flex

          .list-header
            display: flex
            .title
              display: flex
              align-items: center
              cursor: pointer
              margin-right: 0.8rem
              font-weight: $fw-bold

              svg
                width: 1.6rem
                height: 1.6rem

              .icons-chevron-up
                display: none
              .icons-folder-open
                display: none

              div
                margin-left: 0.4rem

          .list
            display: none
            flex-direction: column
            padding-left: 2.4rem

            .list-item
              display: flex
              align-items: center
              justify-content: space-between
              padding: 0.2rem 0

              div:first-child
                display: flex
                cursor: pointer
              .disabled
                opacity: 0.8
                cursor: not-allowed
                :hover
                  cursor: not-allowed
              svg
                width: 1.6rem
                min-width: 1.6rem
                height: 1.6rem
                min-height: 1.6rem
                cursor: pointer
                margin-right: 0.4rem

              .zone-square
                min-width: 1.2rem
                min-height: 1.2rem
                margin: auto 0.4rem auto 0
                border-radius: 0.2rem

        &.devices
          max-height: none
          height: auto
          overflow-y: auto

          .devices-switches
            display: flex
            justify-content: space-between
            padding-bottom: 1.6rem
            color: $ink

            .device-switch
              display: flex
              align-items: center
              width: 16rem
              background: $sky
              padding: 1.2rem
              border-radius: 0.4rem
              .ui-switch
                flex-basis: 100%
                justify-content: space-between

          .buttons-group
            padding-bottom: 1.6rem

            &:last-of-type
              padding-bottom: 0

            .buttons-group-title
              font-weight: $fw-regular
              display: flex
              align-items: center
              span
                margin-right: 0.8rem
                line-height: 3.2rem

            .buttons-group-buttons
              display: flex
              flex-flow: row wrap
              gap: 0.8rem
              .ui-button
                border: 0.1rem solid $sky
                border-radius: 0.8rem
                font-size: 1.6rem
                color: $ink
                &.medium
                  padding: 0 1.6rem
                &.active
                  border-color: $rombit-blue-700
                  color: $rombit-blue-700

          .map-info-tooltip-icon
            .map-info-tooltip
              width: 24rem

.map-search-input
  position: relative
  display: flex
  align-items: center

  svg path
    width: 1.6rem
    height: 1.6rem

  .icons-search
    position: absolute
    z-index: 1
    left: 0.8rem
    width: 1.6rem
    height: 1.6rem
    path
      fill: $ink

  input
    width: 100%
    height: 2.4rem // filter specific
    padding-right: 3.3rem
    padding-left: 2.8rem
    border: 0.1rem solid $sky-dark
    border-radius: 0.4rem
    &::placeholder
      opacity: 1 // Firefox
      color: $input-placeholder-color
    &:focus
      border: 0.2rem solid $rombit-blue-600

  div
    position: absolute
    right: 0.8rem
    cursor: pointer
    line-height: 0
    &:hover path
      fill: $ink
    path
      fill: $ink-lightest

// Going out of my way to make this look as ugly as possible
.map-toolbox
  position: absolute
  z-index: 666
  top: 0
  right: 0
  background: #000000C7
  padding: 1rem 2rem 2rem
  font-family: Consolas, sans-serif
  font-size: 1.4rem
  color: white

  h1
    display: flex
    justify-content: center
    width: 100%
    margin-bottom: 1rem
    font-size: 2rem

  label
    color: white

  .map-toolbox-select
    color: black

  .device-status
    display: flex
    margin-top: 0.8rem
    label
      margin-right: 0.4rem

  button
    display: flex
    justify-content: center
    width: 100%
    background: $white
    cursor: pointer
    padding: 0.7rem 1.8rem
    margin-top: 1rem
    border: $sky-dark 0.2rem solid
    font-size: 1.4rem
    font-weight: 700
    &:hover
      background-color: $rombit-blue-800
      color: $white

.item-following-box-v2
  z-index: 2
  /* stylelint-disable-next-line declaration-no-important */
  bottom: 8rem !important

// Disable google controls for indoor map
.overlay-indoor
  .view-control-mount
    display: none

.mapv2-boedo-move-indoor-select,
.mapv2-boedo-move-outdoor-select
  border: 3px solid $input-border-color
  height: 3.2rem
  border-radius: 0.3rem
  padding: 0 1.6rem
  -webkit-appearance: none
  -moz-appearance: none
  text-indent: 1px
  text-overflow: ""
