@import 'src/sass/_variables'

.buttons-group
  padding-bottom: 1.6rem

  &:last-of-type
    padding-bottom: 0

  .buttons-group-title
    display: flex
    align-items: center
    font-weight: $fw-regular
    span
      margin-right: 0.8rem
      line-height: 3.2rem

  .buttons-group-buttons
    display: flex
    flex-flow: row wrap
    gap: 0.8rem
    .ui-button
      border: 0.1rem solid $sky
      border-radius: 0.8rem
      font-size: 1.6rem
      color: $ink
      &.medium
        padding: 0 1.6rem
      &.active
        border-color: $rombit-blue-700
        color: $rombit-blue-700

.tmv2-clusters-charging
  display: flex
  justify-content: space-between
  margin-bottom: 2.4rem
  color: $ink

  .cluster-charging-switch
    display: flex
    align-items: center
    width: 16rem
    background: $sky
    cursor: pointer
    padding: 1.2rem
    border-radius: 0.4rem

    .ui-switch
      flex-basis: 100%
      justify-content: space-between

.title-info
  display: flex
  align-items: center
  justify-content: flex-start

  h3
    margin-right: 0.8rem

.map-info-tooltip-icon
  position: relative
  width: 1.4rem
  height: 1.4rem
  cursor: pointer

  &:hover
    & > .map-info-tooltip
      display: block

  svg
    opacity: 0.5
    /* stylelint-disable-next-line declaration-no-important */
    width: 1.4rem !important
    /* stylelint-disable-next-line declaration-no-important */
    height: 1.4rem !important

  .map-info-tooltip
    position: absolute
    z-index: 12
    display: none
    transform: translate(-18%, -100%)
    width: 15rem
    background-color: $ink
    padding: 0.8rem
    margin-top: -1rem
    border-radius: 0.4rem
    font-size: 1.2rem
    font-weight: $fw-regular
    text-align: center
    line-height: 1.6rem
    color: $white
