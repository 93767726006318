@import 'src/sass/_variables'

.area-details-tabs
  width: 100%

  & > .header
    display: flex
    align-items: flex-start
    justify-content: flex-start
    width: 100%

    .ui-tabs
      width: 100%

  .active-tab-container
    width: 100%
