@import 'src/sass/_variables'

.ui-rule-actions
  max-height: 20rem
  margin-bottom: 1.6rem
  list-style: none
  overflow-y: auto
  li
    display: flex
    align-items: center
    margin-bottom: 0.8rem
    [class^="icons-"]
      margin-right: 0.8rem
    svg
      width: 1.6rem
      height: 1.6rem
      path
        fill: $green
    span
      font-size: 1.4rem
      line-height: 2rem
      color: $ink

    .recurrent-action
      display: flex
      align-items: center
      background: $rombit-blue-100
      padding: 0.6rem
      margin-right: 0.8rem
      border-radius: 0.4rem
      color: $rombit-blue-600
      .icons-repeat
        path
          fill: $rombit-blue-600

  .action-text
    .action-text-bold
      margin: 0 0.4rem
      font-weight: $fw-bold
