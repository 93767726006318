@import 'src/sass/_variables'

.routes-requests
  .container
    margin: 2rem 4.4rem 4.8rem 3.2rem
    .requests-header
      display: flex
      justify-content: space-between
      > div
        margin-bottom: 1.6rem
        margin-left: auto

    .deleted-subjects
      color: $ink-lighter

    .subjects-tip
      margin-top: 0.4rem
      margin-left: 0.4rem
      font-size: 1.2rem
      font-weight: $fw-regular
      color: $ink-lighter
